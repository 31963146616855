import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Link } from 'react-router-dom';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
// import TicketDetailModal from '../../transactions/ticketTransaction/TicketDetailModal';
// import ItemDemandDetail from '../itemDemand/ItemDemandDetail';
import AllocationDetail from '../../inventory/allocation/AllocationDetail';
import ItemViewInList from '../../inventory/includes/ItemViewInList';
import ConsumptionHistoryModal from '../../inventory/consumption/ConsumptionHistoryModal';

class SalesInvoiceDetailOld extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            invoiceViewData: null,
            view_loading: false,
            itemDetails: null,
            allocationId: '',
            demandId: ''
        };
        this.invoiceHistoryRef = React.createRef();
        this.itemDetailsRef = React.createRef();
        this.ticketDetailRef = React.createRef();
        this.INVOICE_URL = INVENTORY_API_BASE_URL_2 + '/consumption';
        this.INVOICE_Details_URL = INVENTORY_API_BASE_URL_2 + '/sales/invoice/details';
    }

    componentDidMount() {
        if (this.props && this.props.transaction_id) {
            this.getInvoiceViewDetails(this.props.transaction_id)
        }
        if (this.props && this.props.invoiceData) {
            this.setState({ invoiceViewData: this.props.invoiceData })
        }
        this.demandModalView = new Modal(document.getElementById('demandModalView'), { keyboard: false, backdrop: false });
        this.viewItemModal = new Modal(document.getElementById('viewItemModal'), { keyboard: false, backdrop: false });
        this.viewAllocationModal = new Modal(document.getElementById('viewAllocationModal'), { keyboard: false, backdrop: false });
   
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.transaction_id !== this.props.transaction_id) {
            this.getInvoiceViewDetails(nextProps.transaction_id)
        }
        if (nextProps.invoiceData !== this.props.invoiceData) {
            this.setState({ invoiceViewData: nextProps.invoiceData })
        }

    }


    getInvoiceViewDetails = (transaction_id) => {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', this.INVOICE_Details_URL + '/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                invoiceViewData: respData,
                invoiceLoaded: false,
            });
     

        }).then(() => this.setState({ view_loading: false }));

    }

    viewInvoiceModalInit = (data, k) => {
        if (this.state.invoiceViewData) {
            if (this.state.invoiceViewData.assetProfile || this.state.invoiceViewData.workstation) {
                let invoiceData = this.state.invoiceViewData;

                if (data) {
                    this.invoiceHistoryRef.current.modalInit(data, invoiceData?.workstation?.id, invoiceData?.assetProfile?.asset_id, invoiceData.invoice_for)
                }
            } else {
                toast.error('Please Select Asset/Workstation first', { position: toast.POSITION.TOP_RIGHT });
            }
        }

    }


    viewItemModalInit = (item) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    viewAllocationModalInit = (id) => {
        if (id) {
            this.viewAllocationModal.show();
            this.setState({ allocationId: id })
        }
    }

    getTicketDetail = (id) => {
        this.ticketDetailRef.current.ticketModalInit(id)
    }

    demandModalViewInit = (id) => {
        this.setState({
            demandId: id
        }, () => {
            setTimeout(() => this.demandModalView.show(), 1000)
        })

    }

   

    allocationDetailsModalJsx = () => {
        return (
            <div className="modal fade" id="viewAllocationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Allocation Detail </h5>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                <AllocationDetail allocationId={this.state.allocationId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        let invoiceDetails = this.state.invoiceViewData;
        return (<Ax>
            {this.state.view_loading ? <Loader />
                : invoiceDetails ? <div>
                    <table className="table table-bordered bg-white">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{ width: "33%" }}>ID</th>
                                <th style={{ width: "33%" }}>Date</th>
                                <th style={{ width: "33%" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{invoiceDetails.transaction_id}</td>
                                <td scope="col">{invoiceDetails.transaction_date}</td>
                                <td scope="col" className="align-middle">{invoiceDetails.status ? invoiceDetails?.status?.name : "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper" >
                        <span className="content_heading">InVoice Detail</span>
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-3">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }} >Warehouse</td>
                                <td colSpan={2}>{invoiceDetails.warehouse ? <div><b>{invoiceDetails.warehouse.name} ({invoiceDetails.warehouse.code})</b> <br />
                                    {invoiceDetails.warehouse ?
                                        <div className='pt-1'>Address : {invoiceDetails.warehouse.address1 ? invoiceDetails.warehouse.address1 + ", " : ""}
                                            {invoiceDetails.warehouse.address2 ? invoiceDetails.warehouse.address2 + ", " : ""}
                                            {invoiceDetails.warehouse.landmark ? invoiceDetails.warehouse.landmark + ", " : ""}
                                            {invoiceDetails.warehouse.city ? invoiceDetails.warehouse.city + ". " : "-"} <br />
                                            Destination of Supply : <b>{invoiceDetails.warehouse.state_name}</b> <br />
                                            GSTIN  : {invoiceDetails.warehouse_gstin ?? "-"} <br />
                                        </div> : "-"}</div> : "-"} <br />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }} >
                                    {invoiceDetails.invoice_for === 'asset' && "Asset"}
                                    {invoiceDetails.invoice_for === 'workstation' && 'Workstation'} {invoiceDetails.invoice_for === 'sub_assembly' && 'Sub Assembly'}
                                    {invoiceDetails.invoice_for === 'employee' && 'Employee'}
                                </td>
                                <td colSpan={2}>
                                    {invoiceDetails.invoice_for === 'asset' &&
                                        <div className='text-capitalize'><b>{
                                            invoiceDetails.assetProfile.name} ({invoiceDetails.assetProfile?.asset_code ?? "-"})</b> <br />
                                            Asset Type (Asset Matrix) : {invoiceDetails.assetProfile.asset_type_name ?? "-"} ({invoiceDetails.assetProfile.asset_matrix_unique_id ?? "-"}) <br />
                                            Manufacturer (Model) : {invoiceDetails.assetProfile.manufacturer_name ?? "-"} ({invoiceDetails.assetProfile.model_name ?? "-"})<br />
                                            <br />
                                        </div>
                                    }
                                    {
                                        invoiceDetails.invoice_for === 'workstation' &&
                                        <div><b>{invoiceDetails?.workstation?.workstation_name} ({invoiceDetails?.workstation?.workstation_nickname ?? "-"})</b>
                                        </div>
                                    }

                                    {
                                        invoiceDetails.invoice_for === 'sub_assembly' &&
                                        <div><b>{invoiceDetails?.subAssembly?.name} ({invoiceDetails?.subAssembly?.code ?? "-"})</b>
                                        </div>
                                    }
                                    {
                                        invoiceDetails.invoice_for === 'employee' &&
                                        <div><b>{invoiceDetails?.employee?.display_full_name}</b><br />
                                            Mobile Number : {invoiceDetails?.employee?.mobile_display}<br />
                                            Status : <Status color={invoiceDetails?.employee?.status_color}>{invoiceDetails?.employee?.status_text}</Status>
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }} >Customer</td>
                                <th colSpan={2} >{invoiceDetails.customer ? <div><b>{invoiceDetails.customer.name} ({invoiceDetails.customer.code})</b>
                                    <br />
                                </div> : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Deal</td>
                                <td colSpan={2} >{invoiceDetails.deal_data ? invoiceDetails.deal_data.header : "-"}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Sales Order</td>
                                <td colSpan={2} >{invoiceDetails.sales_order ? invoiceDetails.sales_order.sales_order_type_name : "-"}</td>
                            </tr>
                          
                            <tr>
                                <td style={{ width: "33%" }} >Voucher Number & Date</td>
                                <td style={{ width: "33%" }} >{invoiceDetails.voucher_number ? invoiceDetails.voucher_number : "-"}</td>
                                <td style={{ width: "34%" }} >{invoiceDetails.voucher_date_display ? invoiceDetails.voucher_date_display : "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                   

                    <div className="tab_content_wrapper" >
                        <span className="content_heading">Item Detail</span>
                    </div>
                    <table className="table table-sm table-bordered table-responsive bg-white mt-3">
                        <thead className="table-secondary">
                            <tr className="">
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" className="text-center" style={{ width: "27%" }}>Item Details</th>
                                {this.props?.isc_configuration?.track_batch_expiry == 'Y' ? <Ax>
                                    <th scope="col" className="text-center" style={{ width: "10%" }}>Batch Number <br />
                                        Expiry Date
                                    </th>
                                </Ax> : null}
                                <th scope="col" className="text-center" style={{ width: "14%" }}>Sales Order <br />
                                    Sales Allocation
                                </th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Quantity</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Unit Rate</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Discount</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>GST</th>
                                <th scope="col" className="text-center" style={{ width: "7%" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoiceDetails && invoiceDetails.items && invoiceDetails.items.length > 0 ? invoiceDetails.items?.map((i, k) => {
                                    let item_amount = parseFloat(i.qty * i.rate);
                                    return (<tr key={k}>
                                        <td className='text-center'>{k + 1}</td>
                                        <td>{i.item_profile ? <div className='row'>
                                            <div className='col-sm-10'>
                                                <ItemViewInList item={i.item_profile} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                            </div>
                                            <div className='col-sm-2 text-end'>
                                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu">
                                                    <li><a href="#" className="dropdown-item" onClick={() => this.viewInvoiceModalInit(i.item_profile, k)}>InVoice History</a></li>
                                                </ul>
                                            </div>
                                        </div> : null}</td>
                                        {this.props?.isc_configuration?.track_batch_expiry == 'Y' ? <Ax>
                                            <td className="text-center" > BN : {i.batch_number ? i.batch_number : "-"}<br />
                                            ED : {i.expiry_date_display ? i.expiry_date_display : "-"}</td>

                                        </Ax> : null}
                                        <td className="text-center" >SO: {i.demand_transaction_id ? <Link onClick={() => this.demandModalViewInit(i.demand_transaction_id)}>{i.demand_transaction_id}</Link> : "-"}
                                            <br />

                                            SA: {i.allocation_transaction_id ? <Link onClick={() => this.viewAllocationModalInit(i.allocation_transaction_id)}>{i.allocation_transaction_id}</Link> : "-"}
                                        </td>
                                        <td className="text-end" >{i.qty ? i.qty : "-"}</td>
                                        <td className="text-end" >{i.rate ? i.rate : "-"}</td>
                                        <td className="text-end" >{i.item_with_discount ? i.item_with_discount : "-"}</td>
                                        <td className="text-end" >{i.gst ? i.gst : "-"}</td>
                                        <td className='text-end'><CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(item_amount).toFixed(2)} /></td>
                                    </tr>)

                                }) : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                            }
                        </tbody>
                    </table>
                    <div className="row align-items-center mb-5">
                        <div className="col-6">
                            <label htmlFor="notes" className="form-label">Notes</label>
                            <div>{invoiceDetails.notes ?? "-"}</div>

                        </div>
                        <div className="col-6">
                            <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                            <table className="table  table-borderless">
                                <tbody>
                                    <tr className="subtotal_bg_color">
                                        <th>Sub-Total</th>
                                        <td className='float-end'>{invoiceDetails.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(invoiceDetails.total_amount - (invoiceDetails.adjustment_amount)).toFixed(2)} /> : 0.00}</td>
                                    </tr>
                                    <tr className="subtotal_bg_color">
                                        <th>Adjustment</th>
                                        <td className='float-end'>{invoiceDetails.adjustment_amount ?? 0}</td>
                                    </tr>
                                    <tr className="subtotal_bg_color">
                                        <th>Final Total</th>
                                        <td className='float-end'>{invoiceDetails.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={invoiceDetails.total_amount} /> : 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ConsumptionHistoryModal ref={this.invoiceHistoryRef} />
                    <ItemDetailsModal ref={this.itemDetailsRef} />
                    {/* <TicketDetailModal ref={this.ticketDetailRef}/> */}
                </div>

                    : null}
            {this.allocationDetailsModalJsx()}
            {/* {this.demandDetailsModalJsx()} */}
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency: state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps)(SalesInvoiceDetailOld);