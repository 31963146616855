import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DisplayListPagination } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import DateService from '../../../services/DateService';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import QuotationDetailModal from '../quotation/QuotationDetailModal';

class DealsDetail extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initEditDealForm     =   {
            deal_type_id              :   '',
            header                      :   '',
            tagged_users                :   [],
            due_date                    :   null,
            asset_id                    :   '',
            tags                        :   null,
            workstation                 :   '',
            employees                   :   null,
            sub_assembly                :   '',
            estimate_days               :   '',
            failure_start_time          :   '',
            failure_closing_time        :   '',
        }

        this.initAddDocument        =   {
            file                        :   '',
            number                      :   '',
            file_name                   :   null,
            base64_document             :   null
        }

        this.initAddRemarkForm      =   {
            status                      :   null,
            remark                     :   '',
        }

        this.state                  =   {
            dealData                    :   null,
            dealEditForm                :   {...this.initEditDealForm},
            formDataLoading             :   false,
            allDealTypes                :   [],
            allUsers                    :   [],
            additionalAttributes        :   [],
            saveFormSubmitting          :   false,
            addDocumentForm             :   {...this.initAddDocument},
            remark_form_loading         :   false,
            remarkFormData              :   [],
            allRemarkStatus             :   [],
            allAssetStatus              :   [],
            addRemarkForm               :   {...this.initAddRemarkForm},
            other                       :   'N',
            documentTypes               :   [],
            formDataLoading             :   false,
            quotation_loading           :   false,
            quotation_listing           :   [],
            quotationlistingMeta        :   null,
            quotationtotalListingCount  :   0,
            selectedQuotationId         :   '',
            addRemark                   :   props.addRemark && props.addRemark == 'N' ? 'N' : 'Y',
            performAction               :   props.performAction && props.performAction == 'N' ? 'N' : 'Y',
            showOtherDetail             :    props.showOtherDetail && props.showOtherDetail == 'N' ? 'N' : 'Y',
            dealExpenseId               :   '',
            searchedDeal                :   '',
            dealTypeFormData            :   [],
        }

        this.documentsNVoucherBtnRef        =   React.createRef();
        
        this.documentTypeUrl        =   ORG_API_BASE_URL_2  + '/setting/doc_types';
        this.remarkStatusFormDataUrl=   INVENTORY_API_BASE_URL_2 + '/deal/remark_form_data';
        this.remarkStatusUrl        =   INVENTORY_API_BASE_URL_2 + '/deal/change_status_remark';
        this.dealDetailUrl           =   INVENTORY_API_BASE_URL_2 + '/deal/detail';
        this.addDocumentUrl         = INVENTORY_API_BASE_URL_2 + '/deal/document/add';
        this.deleteDocumentUrl      = INVENTORY_API_BASE_URL_2 + '/deal/document/delete';
        this.quotationListUrl       =  INVENTORY_API_BASE_URL_2 + '/quotation/list';
    }

    componentDidMount() {
        if((this.props && this.props.dealId) || (this.props && this.props.dealData) ){
            this.initalizeDetailData(this.props)
        }
    
        this.addRemarksModal            =    new Modal(document.getElementById('addRemarksModal'), {keyboard: false, backdrop :false});
        this.updateDealModal            =    new Modal(document.getElementById('updateDealModal'), {keyboard: false, backdrop :false});
        this.addDocumentModal           =    new Modal(document.getElementById('addDocumentModal'), {keyboard: false, backdrop :false});
        this.viewTransactionModal       =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});             
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.dealId !== this.props.dealId || (nextProps.dealData !== this.props.dealData)){
           this.initalizeDetailData(nextProps)
        }
    }

    initalizeDetailData     =   (pr)    =>  {
        this.setState({
            iam_user_sites : pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`,code: s.site_code}}) : [],
        })
       let deal_id    =   pr && pr.dealId ? pr.ticetId : pr.dealData ? pr.dealData.activity_id :  '';
      let dealData    =  pr.dealData ? pr.dealData : null;
       if(deal_id && !dealData){
            this.getDealDetail(deal_id)
       }else if(dealData){
            this.setState({dealData : dealData},() => {
                if (this.state.dealData && this.state.dealData.config_data &&  this.state.dealData.config_data.enable_deal_quotation == "Y") {
                    this.state.dealData.total_quotation && this.state.dealData.total_quotation.voucher_count > 0 && this.getQuotationList(1, dealData.id);
                } 
            })
       }
    }

    getQuotationList = (page = 1, search_deal_id = "") => {
        this.setState({ quotation_loading: true });
        let params = {page: page,search_deal_ids: search_deal_id ? [search_deal_id] : []};
        HttpAPICall.withAthorization("GET",this.quotationListUrl,this.props.access_token, params,{},(resp) => {
            let respData = resp.data;
            this.setState({
              quotation_listing                 : respData.data,
              quotationlistingMeta              : {
                from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
              },
              quotationtotalListingCount        : respData.total,
            });
          }
        ).then(() => this.setState({ quotation_loading: false }));
      };

    getDealDetail                   =      (id)            =>        {
        this.setState({view_loading : true})
        let params = {};
        HttpAPICall.withAthorization('GET', this.dealDetailUrl + '/' + id, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                dealData        : respData,
                dealHeader      : respData.header,
                
            },() => {
                if (this.state.dealData && this.state.dealData.config_data &&  this.state.dealData.config_data.enable_deal_quotation == "Y") {
                    this.state.dealData.total_quotation && this.state.dealData.total_quotation.voucher_count > 0 && this.getQuotationList(1, id);
                } 
            });
            
        }).then(() => this.setState({view_loading: false}));
    }


   //****************************** UPDATE  DOCUMENT  DETAILS ******************  
   addDocumentModalInit                =      ()            =>       {
    let dealData = this.state.dealData
    if(dealData.documents && dealData.config_data && dealData.documents.length >= dealData.config_data.max_doc){
        toast.error("Maximum number of documents allowed to upload in a Deal exceeded", {position: toast.POSITION.TOP_RIGHT});
    }else{
        this.addDocumentModal.show();
        this.setState({ addDocumentForm    :       {...this.initAddDocument},other:'N'})
        this.loadDocTypesFromApi()
    }
       
   }

    loadDocTypesFromApi = () => {
        this.setState({ formDataLoading: true })
        HttpAPICall.withAthorization('GET', this.documentTypeUrl, this.props.access_token, {doc_type : 'sales_crm_doc_type'}, {}, (response) => {
            this.setState({
                documentTypes: response.data.map(s => { return ({ value: s, label: s }) }),
               
            });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ formDataLoading: false }))
    }

    handleFileChange                    =      async(e)      =>        {
        let file = e.target.files[0];
        let filename = file.name
        const base64_document = await this.convertBase64(file);
        this.setState({addDocumentForm: { ...this.state.addDocumentForm,base64_document : base64_document,file_name:filename}})
    }

    convertBase64                       =      (file)        =>        {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    addDealDocument                   =      (e)           =>       {
        e.preventDefault()
        this.setState({saveFormSubmitting : true})
        let frmData = {
            deal_id       :   this.state.dealData.id,
            name          :   this.state.addDocumentForm.name,
            number        :   this.state.addDocumentForm.number,
            file_name         :   this.state.addDocumentForm.file_name,
            base64_document            :   this.state.addDocumentForm.base64_document,
            deal_id             : this.state.dealData.id
        };
        HttpAPICall.withAthorization('POST', this.addDocumentUrl ,
            this.props.access_token, {}, {...frmData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addDocumentModal.hide();
                this.getDealDetail(this.state.dealData.id)
                this.setState({ addDocumentForm    :       {...this.initAddDocument}})
            }).then(() => this.setState({saveFormSubmitting: false}))
    }

    deleteDocument                      =     (id)          =>       {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.deleteDocumentUrl + '/' + id, this.props.access_token, {},
                        {doc_id : id , deal_id : this.state.dealData.id } ,(response) => {
                            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                            this.getDealDetail(this.state.dealData.id)
                        }).then(() => {

                    });
                }
            });
    }

    //****************************** UPDATE REMARK ****** ****************** 
    getAddRemarkFormData              =         (id)           =>          {
        this.setState({remark_form_loading: true, });
        HttpAPICall.withAthorization('GET', this.remarkStatusFormDataUrl, this.props.access_token, {}, {}, (resp) => {
          
            this.setState( {   remarkFormData  :     resp.data,
                
                allRemarkStatus      :     resp.data.status.map((s) => { return {value: s.id, label: s.name}}),
            },() => {
                this.setState({addRemarkForm         :    {...this.initAddRemarkForm,status : this.state.dealData.status.id},})
            })
        }).then(() => this.setState({remark_form_loading: false}));
    }

    addRemarkInit                     =         ()            =>         {
        this.addRemarksModal.show()
        this.getAddRemarkFormData(this.state.dealData.id)
    }

    submitAddRemarkForm               =       (e)             =>       {
        e.preventDefault()
        
        let frmData = {
              ...this.state.addRemarkForm,
             enc_id                :       this.state.dealData.id
            }
            
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('PUT', this.remarkStatusUrl, this.props.access_token, {} , {...frmData}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.addRemarksModal.hide();
            this.setState({addRemarkForm  :  {...this.initAddRemarkForm}})
            this.getDealDetail(this.state.dealData.id)
            if(this.props.afterUpdate){
                this.props.afterUpdate(this.state.dealData.id)
            }
        }).then(() => this.setState({saveFormSubmitting: false}));
    }

    viewQuotationDetail = (id) => {
        this.setState({ selectedQuotationId: id }, () =>
            this.viewTransactionModal.show()
          );
      };

      addToQuotation = (type = null) => {
        let dealData = this.state.dealData ? this.state.dealData : null;
        
        let obj = {
         deal_header: dealData.header,
         customerData   :   dealData.customer ? dealData.customer : '',
         customerProjectData   :   dealData.customer_project ? dealData.customer_project : '',
        };
        localStorage.setItem("selectedDealToAddQuotation", JSON.stringify(obj));
      };
    
    dealViewJsx                   =       ()             =>        {
        let dealData = this.state.dealData;
       
        return (
            <div className="px-3 pb-3">
                {dealData ? (
                    <Ax>
                        <ul className="nav nav-tabs" id="myTab">
                            <li className="nav-item" role="dealTab">
                                <button className="nav-link active" id="detail-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_1" aria-controls="tab_1"
                                    role="tab" aria-selected="true" > Detail </button>
                            </li>
                            <li className="nav-item" role="dealTab">
                                <button className="nav-link" id="document-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_2" aria-controls="tab_2"
                                    role="tab" aria-selected="false" ref={this.documentsNVoucherBtnRef} > Documents & Voucher </button>
                            </li>

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane show active" id="tab_1" aria-labelledby="detail-tab">
                                <div className="row my-2">
                                    <div className="col-sm-9">
                                        <div className="tab_content_wrapper">
                                            <span className="content_heading"> Deal Details </span>
                                        </div>
                                        <table className="table mt-2 table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Deal ID</td>
                                                    <th style={{ width: "75%" }}>{dealData.transaction_id ? dealData.transaction_id : "-"} </th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Deal Type</td>
                                                    <th style={{ width: "75%" }}>{dealData.deal_type ? dealData.deal_type.name : "-"} </th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Deal Header</td>
                                                    <th style={{ width: "75%" }}> {dealData.header ?? "-"}</th>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: "25%" }}>Description</td>
                                                    <th style={{ width: "75%" }}> {dealData.notes ?? "-"}</th>
                                                </tr>


                                            </tbody>
                                        </table>

                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Tagging Details </span>
                                        </div>
                                        <table className="table my-2 table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Customer</td>
                                                    <th style={{ width: "75%" }}>{dealData.customer ? dealData.customer.name : ""} </th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Customer Project</td>
                                                    <th style={{ width: "75%" }}>{dealData.customer_project ? <span>{dealData.customer_project.project_name} ({dealData.customer_project.project_code})</span> : ""} </th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%" }}>User</td>
                                                    <th style={{ width: "75%" }}>
                                                        {dealData?.users?.length > 0
                                                            ? dealData?.users?.map(
                                                                (item, key) => {
                                                                    return (
                                                                        <span key={key}>
                                                                            {item.full_name}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                }
                                                            )
                                                            : "-"}
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Other Details </span>
                                        </div>
                                        <table className="table my-2 table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Estimate Days</td>
                                                    <th style={{ width: "75%" }}>{dealData.estimate_day ? dealData.estimate_day : ""} </th>
                                                </tr>
                                                {dealData && dealData.config_data && dealData.config_data.enable_deal_tags && dealData.config_data.enable_deal_tags == 'Y' && <tr>
                                                    <td style={{ width: "25%" }}>Tags</td>
                                                    <th style={{ width: "75%" }}>{dealData.tags && dealData.tags.length > 0
                                                        ? dealData.tags.map((tag, i) => tag).join(", ") : "-"} </th>
                                                </tr>}
                                                {dealData && dealData.additional_attribute_data && dealData.additional_attribute_data.length > 0
                                                    ? dealData.additional_attribute_data.map(
                                                        (attr) => {
                                                            return (
                                                                <tr key={attr.key}>
                                                                    <td scope="col" className="text-capitalize">{attr.name ? attr.name : "-"}</td>
                                                                    <th scope="col" colSpan={2}> {attr.value_display ? attr.value_display : "-"}   </th>
                                                                </tr>
                                                            );
                                                        }
                                                    )
                                                    : null}
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Transaction Detail</span>
                                        </div>
                                        <table className="table mt-2 table-hover table-bordered">
                                            <thead className="table-secondary">
                                                <tr>
                                                    <th style={{ width: "15%" }}>Attribute</th>
                                                    <th style={{ width: "15%" }}>Date</th>
                                                    <th style={{ width: "25%" }}>User Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Deal Creation</td>
                                                    <td>{dealData.created_at ? DateService.dateTimeFormat(dealData.created_at, 'DD-MMM-YYYY') : "-"}</td>
                                                    <td>{dealData.created_by ? dealData.created_by.full_name : ""} </td>
                                                </tr>
                                                <tr>
                                                    <td>Last Updated</td>
                                                    <td>{dealData.updated_at ? DateService.dateTimeFormat(dealData.updated_at, 'DD-MMM-YYYY') : "-"}</td>
                                                    <td>{dealData.last_updated_by ? dealData.last_updated_by.full_name : ""}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Remarks</span>
                                        </div>
                                        <div className="row my-1">
                                            <div className="col-12">
                                                {dealData && dealData.description && (
                                                    <div><span className="fw-bold link-primary"> {" "} Opening Description :{" "} </span>
                                                        <br />
                                                        <div className="py-2"> {dealData.description}{" "}</div>
                                                    </div>
                                                )}
                                            </div>
                                            {dealData &&
                                                dealData.remarks &&
                                                dealData.remarks.length > 0
                                                ? dealData.remarks.map((c, k) => {
                                                    return (
                                                        <Ax key={k}>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <span className="fw-bold link-primary"> {c.user ? c.user.full_name : ""} </span>
                                                                    Added a remark on <span className="fw-bold"> {DateService.dateTimeFormat(c.created_at, 'DD-MMM-YY')}</span>
                                                                </div>
                                                                <div className="col-sm-12 my-1"> <span> {c.remark}</span> </div>
                                                            </div>
                                                            <hr />
                                                        </Ax>
                                                    );
                                                })
                                                : null}
                                           {this.state.performAction == "Y" && <div className="text-end pe-0">
                                                <button
                                                    disabled={!this.props.permissions.includes('deal_change_status')}
                                                    className="btn btn-primary"
                                                    onClick={this.addRemarkInit}
                                                >
                                                    Add Remark
                                                </button>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card my-2" style={{ minHeight: "40px" }}>
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            <div className="row">
                                                                <div className="col-sm-9">
                                                                    <span className="content_heading">   Status  </span>
                                                                </div>
                                                                {this.state.performAction == "Y" && <div className="col-sm-3 float-end">
                                                                    <button disabled={!this.props.permissions.includes('deal_change_status')} className="btn btn-link" onClick={() => this.addRemarkInit()}>
                                                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faPencilAlt} alt="item-action" />
                                                                    </button>
                                                                </div>}
                                                                
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Status color={dealData.status.color}>{dealData.status.name} </Status>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="card my-2" style={{ minHeight: "40px" }} >
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            <div className="row">
                                                                <div className="col-sm-9">
                                                                    <span className="content_heading">Total Documents  </span>
                                                                </div>
                                                                {this.state.performAction == "Y" && <div className="col-sm-3 float-end">
                                                                    <button className="btn btn-link" onClick={() => this.addDocumentModalInit()} >
                                                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} alt="item-action" />
                                                                    </button>
                                                                </div>}
                                                                
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Link onClick={() => this.handleDocumentsNVoucher()}>
                                                                {dealData.docs && dealData.docs.length > 0 ? dealData.docs.length : 0}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="card my-2" style={{ minHeight: "95px" }} >
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            <span className="content_heading">Ageing</span>
                                                        </div>
                                                        <div className="mb-1">  Deal Ageing: {dealData.aging}</div>
                                                        <div>Remark Ageing: {dealData.remark_aging} </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {dealData && dealData.config_data && dealData.config_data.enable_deal_quotation && dealData.config_data.enable_deal_quotation == 'Y' && <div className="col-sm-12">
                                                <div className="card my-2" style={{ minHeight: "40px" }}  >
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            <div className="row">
                                                                <div className="col-sm-10">
                                                                    <span className="content_heading">Quotation </span>
                                                                </div>

                                                               {this.state.performAction == 'Y' && <div className="col-sm-2 text-end">
                                                                    <Link target="_blank"
                                                                         disabled={!this.props.permissions.includes('quotation-add')}
                                                                        to={{
                                                                            pathname: "/quotation_add",
                                                                            search: "?" + new URLSearchParams({
                                                                                dealId: dealData.id ? dealData.id
                                                                                    : "",
                                                                            }).toString(),
                                                                        }}
                                                                        onClick={() => this.addToQuotation("demand")}
                                                                    >
                                                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} alt="item-action" />
                                                                    </Link>
                                                                </div>}

                                                            </div>
                                                        </div>
                                                        <div className='mb-1'>
                                                            Amount:{" "}
                                                            {dealData.total_quotation && dealData.total_quotation.total_amount ? (
                                                                <Link onClick={() => this.handleDocumentsNVoucher()}>
                                                                    {dealData.total_quotation.total_amount}
                                                                </Link>
                                                            ) : ("0")}
                                                        </div>
                                                        <div>
                                                            Total Voucher:{" "}
                                                            {dealData.total_quotation && dealData.total_quotation.voucher_count ? (
                                                                dealData.total_quotation.voucher_count
                                                            ) : ("0")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane"
                                id="tab_2"
                                aria-labelledby="document-tab"
                            >
                                <div className="tab_content_wrapper mt-2">
                                    <span className="content_heading">Documents</span>
                                </div>
                                <table className="table mt-2 table-hover  table-bordered">
                                    <tbody>
                                        {dealData &&
                                            dealData.docs &&
                                            dealData.docs.length > 0 ? (
                                            dealData?.docs?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td scope="col" style={{ width: "5%" }}>
                                                            {index + 1}
                                                        </td>
                                                        <td
                                                            scope="col"
                                                            style={{ width: "65%" }}
                                                            className="text-capitalize"
                                                        >
                                                            {item.name}
                                                            {item.number && `(${item.number})`}
                                                        </td>
                                                        {item.longitude && item.latitue && (
                                                            <td
                                                                scope="col"
                                                                style={{ width: "6%" }}
                                                                className="text-center"
                                                            >
                                                                <a
                                                                    href={`https://www.google.com/maps/search/${item.latitude},${item.longitude}`}
                                                                >
                                                                    <TapIcon.FontAwesomeIcon
                                                                        icon={TapIcon.faMapMarker}
                                                                    />
                                                                </a>
                                                            </td>
                                                        )}
                                                        <td
                                                            scope="col"
                                                            className="text-center"
                                                            style={{ width: "6%" }}
                                                        >
                                                            <a
                                                                href={item.path}
                                                                target="_blank"
                                                                className="asset_link"
                                                            >
                                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEye} />
                                                            </a>
                                                        </td>
                                                       
                                                        {this.state.performAction == "Y" && (
                                                            <td
                                                                className="text-center"
                                                                style={{ width: "6%" }}
                                                                onClick={() => this.deleteDocument(item.id)}
                                                            >
                                                                <TapIcon.FontAwesomeIcon
                                                                    icon={TapIcon.faTrashAlt}
                                                                    className="asset_link"
                                                                />
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    No Documents Added
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {dealData && dealData.config_data && dealData.config_data.enable_deal_quotation && dealData.config_data.enable_deal_quotation == "Y" && (
                                    <Ax>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Quotation</span>
                                        </div>
                                        <table className="table table-hover table-bordered table-sm my-2">
                                            <thead className="table-secondary">
                                                <tr>
                                                    <th colSpan={6}>Total Quotation Amount</th>
                                                    <td className="text-end">{dealData.total_quotation ? dealData.total_quotation.total_amount : "-"}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="col" style={{ width: "5%" }}>
                                                        S.no
                                                    </th>
                                                    <th scope="col" style={{ width: "15%" }}>
                                                        Date
                                                    </th>
                                                    <th scope="col" style={{ width: "15%" }}>
                                                        Quotation ID
                                                    </th>
                                                    <th scope="col" style={{ width: "25%" }}>
                                                        Customer
                                                    </th>
                                                    <th scope="col" style={{ width: "12%" }}>
                                                        Validity
                                                    </th>
                                                    <th scope="col" style={{ width: "12%" }}>
                                                        Amount
                                                    </th>
                                                    <th scope="col" style={{ width: "16%" }}>
                                                        Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.quotation_loading ? (
                                                    <tr>
                                                        <td className="text-center" colSpan="7">
                                                            <Loader />
                                                        </td>
                                                    </tr>
                                                ) : this.state.quotation_listing &&
                                                    this.state.quotation_listing.length > 0 ? (
                                                    <>
                                                        {this.state.quotation_listing.map(
                                                            (item, index) => {

                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{this.state.quotationlistingMeta ? this.state.quotationlistingMeta.from + index : index}</td>
                                                                        <td><a role="button" className="link-primary text-center" onClick={() => this.viewQuotationDetail(item.transaction_id)}>{item.transaction_id}</a></td>
                                                                        <td>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : ""}</td>
                                                                        <td className='text-capitalize'>{item.customer ? <div>{item.customer.name}</div> : "-"}</td>

                                                                        <td className="text-center">{item.validity_date ? DateService.dateTimeFormat(item.validity_date, 'DD-MMM-YYYY') : ''}</td>
                                                                        <td className="text-end">{item.currency ? item.currency : "INR"} <CurrencyFormatter currencyCode={item.currency ? item.currency : "INR"} amount={parseFloat(item.total_amount_vc ? item.total_amount_vc : 0).toFixed(2)} />

                                                                        </td>
                                                                        <td className='text-center'>{item.status ? item.status.name : '-'}</td>

                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <td className="text-center" colSpan={7}>
                                                            No Records
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <DisplayListPagination
                                            meta={this.state.quotationlistingMeta}
                                            onPageChange={(e) =>
                                                this.getQuotationList(e.selected + 1)
                                            }
                                        />
                                    </Ax>
                                )}

                            </div>

                        </div>
                    </Ax>
                ) : null}
            </div>
        );
    }

    handleDocumentsNVoucher     =   () => {
        this.documentsNVoucherBtnRef.current.click();
    }

    addDocumentModalJsx                 =      ()            =>       {
        return (
            <div className="modal fade" id="addDocumentModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Document</h5>
                            <button type="button" className="btn-close" onClick={() => this.addDocumentModal.hide()}></button>
                        </div>
                        {this.state.formDataLoading ? <Loader />
                            :
                            <form id="addModalForm" onSubmit={this.addDealDocument}>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document Type</label>
                                        </div>
                                        <div className="col-sm-7 align-items-center">
                                            {this.state.other == "Y"
                                                ? <input
                                                    name="name"
                                                    type="text"
                                                    value={this.state.addDocumentForm.name}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                                    placeholder="Please enter Type Name"
                                                    required={true}
                                                // autoFocus={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.documentTypes}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addDocumentForm', 'name')}
                                                    value={this.state.documentTypes.find(u => this.state.addDocumentForm.name == u.value)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Select Document Type"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="other"
                                                type="checkbox"
                                                value={this.state.other}
                                                onChange={(e) => { this.setState({ other: this.state.other == 'N' ? 'Y' : 'N' }) }}
                                                checked={this.state.other == 'Y'}
                                                className="form-check-input"
                                                id="other"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="other">New </label>
                                        </div>


                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <label className="form-label">Document Number</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="number"
                                                type="text"
                                                value={this.state.addDocumentForm.number}
                                                onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Enter Document Number"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="file"
                                                type="file"
                                                value={this.state.addDocumentForm.file}
                                                onChange={(e) => {
                                                    this.formInputHandler(e, "addDocumentForm");
                                                    this.handleFileChange(e)
                                                }}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Upload Document"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => { this.setState({ addDocumentForm: { ...this.initAddDocument }}); this.addDocumentModal.hide() }} disabled={this.state.saveFormSubmitting ? true : false}>Close </button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    addRemarkModalJsx       =   ()  =>  {
        return (
            <div className="modal fade" id="addRemarksModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addRemrk">Add Remark</h5>
                            <button type="button" className="btn-close" onClick={() => this.addRemarksModal.hide()}></button>
                        </div>
                        {this.state.remark_form_loading ? <Loader /> :
                            <form className="bg-white p-2" onSubmit={this.submitAddRemarkForm}>
                                <div className="modal-body">
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3"><label className="form-label require">Deal Status</label></div>
                                        <div className="col-sm-5">
                                            <TapSelect
                                                options={this.state.allRemarkStatus}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRemarkForm', 'status')}
                                                value={this.state.allRemarkStatus.find(a => a.value == this.state.addRemarkForm.status)}
                                                isSearchable={true}
                                                isClearable={false}
                                                required={true}
                                                placeholder="Please Select Status"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3"><label className="form-label require">Remarks</label></div>
                                        <div className="col-sm-5">
                                            <textarea
                                                required={true}
                                                name="remark"
                                                value={this.state.addRemarkForm.remark}
                                                onChange={(e) => this.formInputHandler(e, 'addRemarkForm')}
                                                className="form-control"
                                                style={{ height: "95px" }}
                                            />
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                <div className='modal-footer'>
                                    <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addRemarkForm: { ...this.initAddRemarkForm } }) }}>Close </button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                                
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.selectedQuotationId ? this.state.selectedQuotationId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                          <QuotationDetailModal quotationID={this.state.selectedQuotationId}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
       
        return (<Ax>
           
          {this.state.view_loading  ? <div className='text-center'><Loader/></div> : this.dealViewJsx()}
    
          {this.addDocumentModalJsx()}
          {this.addRemarkModalJsx()}
          {this.transactionDetailsModalJsx()}
          
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        group_permission        :   state.app.acl_info
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DealsDetail);