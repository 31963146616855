import React, { useState, useEffect } from 'react';
import style from './Index.module.css';
import DatePicker from "react-datepicker";
import Ax from '../../hoc/Ax';
import moment from "moment";
import tapIcon from '../../../services/TapIcon';
import TapSelect from "../../ui/TapSelect";

const PeriodSelectionWithCustom               = (props)  =>  {
 
    let initSelectedPeriod                              =   props.value ? props.value : '';
    let tapSelectOptions                                =   props.periods.map((m) => { return {value: m.key, label: m.display}});
    let initStartDate                                   =   props.value !== '' && props.startDate ? moment(props.startDate).toDate() : null;
    let initEndDate                                     =   props.value !== '' && props.endDate ? moment(props.endDate).toDate() : null;
      
    const [dateRange, setDateRange]                     =   useState([initStartDate, initEndDate]);
    const [selectedPeriod, setSelectedPeriod]           =   useState(initSelectedPeriod);
    const [startDate, endDate]                          =   dateRange;
    
    useEffect(() => changePeriodHandler(), [selectedPeriod, startDate, endDate]);
    useEffect(() => tapSelectChange(initSelectedPeriod ? tapSelectOptions.find(o => o.value === initSelectedPeriod) : null), [initSelectedPeriod]);
    
    const changePeriodHandler                           =   ()  =>  {
        props.onSelectPeriod(selectedPeriod, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    }

    const tapSelectChange                               =   (selectedOption)  =>  {
        if(selectedOption && selectedOption.value) {
            let sp                                  =   props.periods.find(p => p.key === selectedOption.value);
            setDateRange(sp && sp.key !== 'custom' ? [moment(sp.dateFromYmd).toDate(),moment(sp.dateToYmd).toDate()] : [null,null]);
            setSelectedPeriod(selectedOption.value);
        } else {
            setSelectedPeriod('');
            setDateRange([null,null]);
        }
    }
    
    return (<Ax>
      <div className="row">
            <div className="col-sm-5">
                <TapSelect
                    menuPlacement={props.menuPlacement ? props.menuPlacement : "top"}
                    isClearable={true}
                    placeholder="Select Period"
                    required={props.required ? props.required : false}
                    isSearchable={true}
                    options={tapSelectOptions}
                    value={tapSelectOptions.find(o => o?.value == selectedPeriod)}
                    changeEvent={(selectedOption) => {
                        tapSelectChange(selectedOption);
                    }}
                    containerHeight={props.containerHeight ? props.containerHeight : null}
                    fontSize={props.fontSize ? props.fontSize : null}
                />

            </div>
            <div className="col-sm-7">
                <div className={['add_calender_section pt-0', props.className ? props.className : ''].join(' ')}>
                    <DatePicker
                        selectsRange={true}
                        monthsShown={2}
                        dateFormat="dd-MMM-yyyy"
                        className={['form-control', props.className ? props.className : ''].join(' ')}
                        onChange={(update) => { setDateRange(update); changePeriodHandler(); }}
                        startDate={startDate}
                        endDate={endDate}
                        required={props.required || true}
                        disabled={selectedPeriod !== 'custom'}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" />
                </div>
            </div>
        </div>
    </Ax>);
};


export default PeriodSelectionWithCustom;