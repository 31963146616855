import React from 'react';
import moment from 'moment';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import WarehouseAddress from '../includes/WarehouseAddress';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import ConsumptionHistoryModal from '../consumption/ConsumptionHistoryModal';
import POHistoryModal from '../purchaseOrder/POHistoryModal';
import ItemSearchModal from '../../items/itemSearchModal';
import ItemViewInList from '../includes/ItemViewInList';
import NumberFormatter from '../../../services/NumberFormater';
import TapInputNumber from '../../../components/ui/TapInputNumber';



class RequisitionAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initRequisitionForm        =   {
            warehouse_id                    :   '',
            requisition_transaction_id      :   '',
            requisition_date                :   moment(new Date(),'YYYY-MM-DD').toDate(),
            title                           :   '',
            priority                        :   '',
            due_date                        :   '',
            notes                           :   '',
            site_id                         :   '',
            asset_id                        :   '',
            users                           :   [],
            status                          :   '',
            currency                        :   'INR',
            conversion_rate                 :   1,
            warehouse_indent_transaction_id           :   ''
        };

        this.state                      =     {
            saveFormSubmitting           :          false,
            requisitionItemRows          :          [{item : null}],
            addRequisitionForm           :          {...this.initRequisitionForm},
            isc_configuration            :          {},
            all_warehouses               :          [],
            warehouseDetails             :          null,
            searchedItem                 :          null,
            requisitionFormDataLoaded    :          false,
            details_loading              :          false,
            allGstSlabs                  :          [],
            requisitionData              :          null,
            selectedAsset                :          null,
            warehouseSites               :          [],
            selectedWorkstation          :          null,
            allStatusOptions              :          [],
            allPriorityOptions           :           [],
            requisitionAddFormData       :          null,
            itemSearchedDetails        :            null,
            itemRowNumber               :           0,
            editCurrency                :          false,
            allCurrencyList             :           [],
            defaultCurrency             :           null,
            currency_loading            :          false,
            warehouseDetailLoading      :          false,
            warehouseDetail             :           null,
            requisitionDetails          :           null,
            indent_detail_loading       :           false,
            indentDetail                :           null,
            indentTransId               :           '',
            allWarehouses               :           [],
            allIndentList               :           [],
            selectedIndentItemList      :           [],
            approved_listing_loading    :           false,
            change_indent_warehouse     :           false,
            showItemSearchModal             :       false,
            showItemAddModal                :       false,
            showItemEditModal               :       false
        };
        this.convertCurrencyRef         =         React.createRef();
        this.addItemModalRef            =         React.createRef();
        this.editItemModalRef           =         React.createRef();
        this.consumptionHistoryRef      =         React.createRef();
        this.poHistoryRef               =         React.createRef();
        this.itemSearchRef              =         React.createRef();
        
        this.itemDetailsRef             =   React.createRef();
        this.handleClickOutside         =         this.handleClickOutside.bind(this);
        this.currency_convert_url       =   ORG_API_BASE_URL_2;

        this.warehouseIndentItemWiseUrl =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/item_wise';
        this.requisitionUrl             =   INVENTORY_API_BASE_URL_2 + '/requisition';
        this.warehouseIndentUrl         =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent';
        this.warehouseUrl               =   ISC_API_BASE_URL_2  + '/warehouse';
        this.itemUrl                    =   INVENTORY_API_BASE_URL_2 + '/item';
    }

    
    componentDidMount() {
        this.initilaizeFormData(this.props)
        document.addEventListener('mousedown', this.handleClickOutside);

        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.viewIndentDetailModal      =   new Modal(document.getElementById('viewIndentDetailModal'), {keyboard: false, backdrop :false});
        this.pickIndentItemModal      =   new Modal(document.getElementById('pickIndentItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(this.props.location && this.props.location.state){
            if(nextProps.all_warehouses !== this.props.all_warehouses) {
                this.initilaizeFormData(nextProps)
             }  
        }        
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    //*******************INITIALIZE FORM DATA************************************** */
    initilaizeFormData                  =   (pr) =>  {

        if(!this.state.requisitionFormDataLoaded) {
           HttpAPICall.withAthorization('GET', this.requisitionUrl + '/add_form_data', this.props.access_token, null, null, (response) => {
                let formData = response.data

               this.setState({
                   requisitionFormDataLoaded       :       formData ? true : false,
                   requisitionAddFormData          :       formData,
                   allCurrencyList                 :       formData && formData.currency_list && formData.currency_list.length > 0 ? formData.currency_list.map((s) => { return {value: s.code, label: s.code}; }) : [],
                   allGstSlabs                     :       formData && formData.gst_slabs && formData.gst_slabs.length > 0 ?  formData.gst_slabs.map((g) => { return {value: g, label: `${g}%`}; }) : [],
                   allStatusOptions                :       formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                   allPriorityOptions              :       formData && formData.priorities && formData.priorities.length > 0 ? formData.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                   allWarehouses                   :       pr.all_warehouses  ? pr.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_id}; }) : [],          
                   allUsers                         :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],          
                   defaultCurrency                 :       formData && formData.default_currency ? formData.default_currency.code : 'INR',
                   addRequisitionForm              :       {
                       ...this.initRequisitionForm,
                       currency                     :      formData && formData.default_currency ? formData.default_currency.code : 'INR'
                   }
                }, () => {
                    if(pr.location && pr.location.state && pr.location.state.requisitionData){
                        let requisitionData = pr.location.state.requisitionData
                        
                        this.setState({
                            addRequisitionForm            :       {
                                ...this.state.addRequisitionForm,
                                warehouse_id                :   requisitionData.warehouse_id ? requisitionData.warehouse_id : '',
                                transaction_id              :   requisitionData.transaction_id ? requisitionData.transaction_id : '',
                                requisition_date            :   pr.location && pr.location.state && pr.location.state.clone ? moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') : requisitionData.transaction_date ,
                                title                       :   requisitionData.title ? requisitionData.title : '',
                                priority                    :   requisitionData.priority ? requisitionData.priority : '',
                                due_date                    :   requisitionData.due_date ? requisitionData.due_date : '',
                                notes                       :   requisitionData.notes ? requisitionData.notes : '',
                                status                      :   requisitionData.status ? requisitionData.status : '',
                                currency                    :   requisitionData.currency ? requisitionData.currency : '',
                                conversion_rate             :   requisitionData.conversion_rate ? requisitionData.conversion_rate : '',
                                asset_id                    :   requisitionData.asset_id ? requisitionData.asset_id : '',
                                users                       :   requisitionData.users ? requisitionData.users : [],
                                warehouse_indent_transaction_id     :   requisitionData.warehouse_indent ? requisitionData.warehouse_indent.transaction_id : ""
                            },
                            requisitionDetails              :         pr.location && pr.location.state && pr.location.state.clone ? null : requisitionData ,
                            requisitionItemRows             :          requisitionData.items.map(i => {
                                
                                                                            i.qty = i.pending_qty; return {...i ,item : {...i.item  , isAddRemark : i.remark ? true : false},indent_id : requisitionData.warehouse_indent ? requisitionData.warehouse_indent.transaction_id : "" ,pending_qty : i.pending_qty && i.indent_data ? (i.indent_data.pending_qty + i.qty) : i.pending_qty} ;
                                                                        }),
                             canEdit                          :         requisitionData.can_edit == 'Y'  ? 'Y'   : 'N',
                             allStatusOptions                :        pr.location && pr.location.state && pr.location.state.clone ? this.state.allStatusOptions : requisitionData && requisitionData.user_status && requisitionData.user_status.length > 0 ?  requisitionData.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                          }, () => {
                             this.getWarehouseDetail(requisitionData.warehouse_id);
                             this.getAssetDetails(requisitionData.asset_id);
                             this.getAllWarehouseIndentList(requisitionData.warehouse_id)
                        })
                    }
                    if(pr.location && pr.location.state && pr.location.state.addRequisitionFromIndent){
                        let requisitionData        =       pr.location.state.addRequisitionFromIndent;
                        let requisitionItems       =       requisitionData.items;
                        let warehouseIndentData    =      requisitionData.warehouseIndentData;
                        let warehouseItemDataList  =      warehouseIndentData && warehouseIndentData.itemDataList && warehouseIndentData.itemDataList.length > 0 ? warehouseIndentData.itemDataList  : []
                        
                        this.setState({
                            addRequisitionForm          :       {
                                ...this.state.addRequisitionForm,
                                warehouse_id                :   requisitionData.warehouse_id ? requisitionData.warehouse_id : '',
                                warehouse_indent_transaction_id       :   requisitionData.indent_id ? requisitionData.indent_id : '',
                                title                                 :   warehouseIndentData && warehouseIndentData.title ? warehouseIndentData.title : '',
                                asset_id                              :   warehouseIndentData && warehouseIndentData.assetProfile && warehouseIndentData.assetProfile.asset_id ? warehouseIndentData.assetProfile.asset_id : '',
                            },
                            selectedAsset                             :    warehouseIndentData && warehouseIndentData.assetProfile ? {...warehouseIndentData.assetProfile, label: warehouseIndentData.assetProfile.name +' ('+warehouseIndentData.assetProfile.asset_code+')' } : null,
                        },() => {
                            
                            let item_ids = requisitionItems && requisitionItems.length > 0 ? requisitionItems.map(it => it.item_id) : [];
                            this.getWarehouseDetail(requisitionData.warehouse_id);
                            this.getAllWarehouseIndentList(requisitionData.warehouse_id);
                            if(requisitionData.indent_id){
                                this.allItemWiseIndentListing(requisitionData.indent_id,item_ids,warehouseItemDataList)
                            }
                            
                            
                        })
                       
                      
                    }
                    if(pr.location && pr.location.state && pr.location.state.itemDemandPRData){
                        let requisitionData        =       pr.location.state.itemDemandPRData;
                        let requisitionItems       =       requisitionData.demandData.items;
                        let warehouseIndentData    =      requisitionData.warehouseIndentData;
                        let warehouseItemDataList  =      warehouseIndentData && warehouseIndentData.itemDataList && warehouseIndentData.itemDataList.length > 0 ? warehouseIndentData.itemDataList  : []
                        this.setState({
                            addRequisitionForm          :       {
                                ...this.state.addRequisitionForm,
                                warehouse_id                :   requisitionData.warehouse_id ? requisitionData.warehouse_id : '',
                                warehouse_indent_transaction_id       :   requisitionData.indent_id ? requisitionData.indent_id : '',
                                title                                 :   warehouseIndentData && warehouseIndentData.title ? warehouseIndentData.title : '',
                                asset_id                              :   warehouseIndentData && warehouseIndentData.assetProfile && warehouseIndentData.assetProfile.asset_id ? warehouseIndentData.assetProfile.asset_id : '',
                                notes                       :   requisitionData.notes ? requisitionData.notes : '',
                            },
                            selectedAsset                             :    warehouseIndentData && warehouseIndentData.assetProfile ? {...warehouseIndentData.assetProfile, label: warehouseIndentData.assetProfile.name +' ('+warehouseIndentData.assetProfile.asset_code+')' } : null,
                            requisitionItemRows             :          requisitionData.demandData.items.map(i => {
                                i.qty = i.pending_qty; return {...i ,item : {...i.item_profile  , isAddRemark : i.remark ? true : false},indent_id : requisitionData.warehouse_indent ? requisitionData.warehouse_indent.transaction_id : "" ,pending_qty : i.pending_qty && i.indent_data ? (i.indent_data.pending_qty + i.qty) : i.pending_qty} ;
                            }),
                            
                        },() => {
                            
                            let item_ids = requisitionItems && requisitionItems.length > 0 ? requisitionItems.map(it => it.item_id) : [];
                            
                            this.getWarehouseDetail(requisitionData.warehouse_id);
                            this.getAllWarehouseIndentList(requisitionData.warehouse_id);
                            if(requisitionData.indent_id){
                                this.allItemWiseIndentListing(requisitionData.indent_id,item_ids,warehouseItemDataList)
                            }
                            
                            
                        })
                       
                    }
               });
           });
       }

    }

    getWarehouseDetail                  =   (warehouse_id)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null,selectedAsset : null,addRequisitionForm : {...this.state.addRequisitionForm,asset_id : ''}});
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                let sites = respData.data && respData.data.sites.length > 0 ? respData.data.sites.map(st => st.id) : []
                this.setState({
                    warehouseDetail         :   respData.data,
                    allUsers                :   this.state.allUsers && this.state.allUsers.length > 0 && sites && sites.length > 0 ?  this.state.allUsers.filter(us =>  us.site_ids.some(value => sites.includes(value))) : this.state.allUsers
                });
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null})
        }
    }
    
    //function to get asset card details
    getAssetDetails                     =     (id)    =>  {
        if(id) {
            this.setState({card_loading : true})
            HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/' + id, this.props.access_token)
                .then((response) => {
                    let asset = response.data.data;
                    this.setState({selectedAsset : {...asset, label: asset.name +' ('+asset.asset_code+')' }});
                });
        } else {
            this.setState({selectedAsset : null});
        }
        
    }

    //********************GET ITEM DETAILS ******************************* */

    onItemSelect                =           (selectedItem,key,edit = false,addRow= true)          =>      {
       
        if(selectedItem && selectedItem.value) {
            let itemdata                    =   selectedItem.itemdata;
            let requisitionItemRows     =   this.state.requisitionItemRows;

            if(this.state.requisitionItemRows.find((req_item) => req_item.item && req_item.item.id == itemdata.id) && edit == false){
                toast.error('This Item is already available in this  Requisition, please update the item', {position: toast.POSITION.TOP_RIGHT })
            } else{
                let row                         =   {...requisitionItemRows[key], item : itemdata , qty : selectedItem.qty ? selectedItem.qty : this.state.requisitionItemRows[key].qty ? this.state.requisitionItemRows[key].qty : 0  , rate : selectedItem.rate ? selectedItem.rate : itemdata.purchase_price ? itemdata.purchase_price : 0, gst :   selectedItem.gst ? selectedItem.gst : itemdata.gst,measuring_unit: itemdata.unit_name};
                requisitionItemRows[key]            =   row;
              

                this.setState({requisitionItemRows : [...requisitionItemRows]}, () => {
                    if(!this.state.requisitionItemRows.find(ir => !ir.item)) {
                        if(addRow == true){
                            setTimeout(() => {this.addNewItemRow()}, 1000);
                        }
                    }
                    
                });
            }
            
        }
    }

    addNewItemRow                           =   ()  =>  {
        this.setState({requisitionItemRows : [...this.state.requisitionItemRows, {item : null}] });
    }

    onItemInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val ;
        if(name == 'gst') {
            if(selectedOption && selectedOption.value) {
                val                         =   parseInt(selectedOption.value);
            }
        }else {
            val = e.target.type == "number" ? parseFloat(e.target.value) : e.target.value;
        }
        let allRows                 =   this.state.requisitionItemRows;
        allRows[k]                  =   {...this.state.requisitionItemRows[k] , [name] : val};
        this.setState({requisitionItemRows : allRows});
    }

    itemViewModalInit                       =   (item,k)  =>  {
        if(item){
            this.itemDetailsRef.current.modalInit(item);
            this.setState({itemRowNumber : k})
        }

    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.requisitionItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
               
            }
        });
        
    }

    editItemModalInit          =       (data,k)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal,itemSearchedDetails:data,itemRowNumber:k }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.requisitionItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });    
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.requisitionItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.requisitionItemRows[k] , item  : itemdata};
        this.setState({requisitionItemRows : allRows});
    }

    submitEditItem          =       ()      =>      {
    
        HttpAPICall.withAthorization('GET', this.itemUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,true)
               });
        })
    }

    removeItemRow                           =   (k) =>  {
        let allRows                 =   this.state.requisitionItemRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({requisitionItemRows : allRows});
        }
    }

    convertCurrency      =     (selectedOption)   =>  {

        let params = {to : this.state.addRequisitionForm.currency , from : selectedOption.value}
       this.setState({currency_loading : true})
        HttpAPICall.withAthorization('GET', this.currency_convert_url + '/currency/convert', this.props.access_token, params, null, (response) => {
            let resp = response.data
            this.setState({
                addRequisitionForm: {
                    ...this.state.addRequisitionForm,
                    conversion_rate: resp.rate
                },
                editCurrency    :  false
            });
       }).then(() => this.setState({currency_loading : false}))
    }
    
    handleClickOutside          =   (event) =>  {
            if (this.convertCurrencyRef && this.convertCurrencyRef.current && !this.convertCurrencyRef.current.contains(event.target)) {
                this.setState({editCurrency : false})
            }
        }

    //****************************CLOSE REQUISITION *********************/
    closeEvent              =           ()      =>      {
        if(this.state.requisitionDetail){
           
        }else{
            this.props.history.push({pathname: `/requisition_list`})
        }
    }

    //**********************REQUISTION FORM  SUBMIT ***************************/

    addRequisitionFormSubmitEvent          =           (e, status = 0)          =>      {
        e.preventDefault()
        let items                   =        [...this.state.requisitionItemRows];
        
        let itemsLists              =        items.filter(i => i.item ? true : false).map(i => {
                                                 return {...i, id : i.item.id, item : null,rate : i.rate ? i.rate : 0};
                                                });
        let addRequisitionForm       =      {...this.state.addRequisitionForm , requisition_date : moment(this.state.addRequisitionForm.requisition_date,'YYYY-MM-DD').format('YYYY-MM-DD'),status : status == 1 ? status : this.state.addRequisitionForm.status }                          

        let showErrors                  =   [];
        let formData                        =  { ...addRequisitionForm,items : itemsLists};

        if(formData.requisition_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }

        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status.'];
        }
     
        if(itemsLists.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their qty.'];
        } else if(items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors                      =   [...showErrors , 'Item quantity must be more than 0.'];
        }
        else if(items.find(i => i.indent_id && i.pending_qty && parseFloat(i.qty) > i.pending_qty )) {
            showErrors                      =   [...showErrors , 'Item quantity cannot be more than Pending Qty'];
        }
        
       
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            if(this.state.requisitionDetails){
                this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('PUT', this.requisitionUrl, this.props.access_token, {}, { ...formData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/requisition_list`, state: {requisitionId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }else{
                this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('POST', this.requisitionUrl, this.props.access_token, {}, { ...formData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/requisition_list`, state: {requisitionId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
           
        }
       
    }

    viewConsumptionModalInit                       =   (data,k)  =>  {

        if (data) {
            this.consumptionHistoryRef.current.modalInit(data,this.state.addRequisitionForm.asset_id)
        }    
    }

    viewPOHistoryModalInit                       =   (data,k)  =>  {
         if (data) {
            this.poHistoryRef.current.modalInit(data, this.state.addRequisitionForm.warehouse_id)
        }
       
    }

    //*****************SHOW INDENT LIST ***************** *

    getAllWarehouseIndentList        =       (id)      =>      {
        let params = {search_warehouse_ids : [id],search_status_ids : [3,4],per_page : 50,page:1,more_than_pending_qty        :       0,}
        HttpAPICall.withAthorization('GET',  this.warehouseIndentUrl, this.props.access_token, {...params},null , (resp) => {
            let respData = resp.data;
            this.setState({
                allIndentList : respData && respData.data && respData.data.length > 0 ? respData.data.map(dt => {return({value : dt.transaction_id,label : dt.transaction_id})}) : []
            })
            
        },(err) => {
            
        })
   
    }

    allItemWiseIndentListing       =       (transaction_id,item_id = [] ,indentItemDataList=[])    => {
     
        if(transaction_id){
            this.setState({indent_detail_loading : true})
            let params          =   {
                                         need_item_full_profile     :       'Y',
                                         search_transaction_id      :       transaction_id,
                                         page   :1,per_page:50,
                                         search_warehouse_id        :       this.state.addRequisitionForm.warehouse_id,
                                         search_item_ids            :       item_id
                                    };
            HttpAPICall.withAthorization('GET', this.warehouseIndentItemWiseUrl, this.props.access_token, params, {}, (resp) => {
                
                let respData            =       resp.data;
                let requisitionItemRows     =   this.state.requisitionItemRows;
                if(respData && respData.data && respData.data.length > 0){
                    respData.data.map((itemData,key) => {
                        let selectedIndentItem =  indentItemDataList && indentItemDataList.length > 0 ? indentItemDataList.find(it => it.id == itemData.item.id) : null;
                       
                         let row                    =           {
                                                                    ...requisitionItemRows[key], 
                                                                    item        :       {...itemData.item,isAddRemark : selectedIndentItem && selectedIndentItem.remark ? true : false}  ,
                                                                    qty         :       itemData.pending_qty , 
                                                                    rate        :       itemData.item && itemData.item.purchase_price ? itemData.item.purchase_price : 0,
                                                                    gst         :       0,
                                                                    pending_qty :       itemData.pending_qty,
                                                                    indent_id   :       itemData.transaction_id,
                                                                    remark      :       selectedIndentItem && selectedIndentItem.remark ? selectedIndentItem.remark : '',
                                                                };
                        requisitionItemRows[key]    =   row;
                    })
                    this.setState({
                        requisitionItemRows         :       [...requisitionItemRows],
                        selectedIndentItemList      :       respData.data  
                    });
                }

            
            }).then(() => this.setState({listing_loading: false,indent_detail_loading : false}));
        }
    }

    pickIndentItemModalInit         =       ()          =>      {
        this.pickIndentItemModal.show();
        if(this.state.addRequisitionForm.warehouse_indent_transaction_id){
            this.setState({approved_listing_loading : true})
            let params          =   {
                                         need_item_full_profile     :       'Y',
                                         search_transaction_id      :       this.state.addRequisitionForm.warehouse_indent_transaction_id,
                                         page   :1,per_page:50,
                                         search_warehouse_id        :       this.state.addRequisitionForm.warehouse_id,
                                    };
            HttpAPICall.withAthorization('GET',  this.warehouseIndentItemWiseUrl, this.props.access_token, params, {}, (resp) => {
                
                let respData            =       resp.data;
            
                this.setState({
                    selectedIndentItemList      :       respData.data  
                });
                

            
            }).then(() => this.setState({approved_listing_loading : false}));
        }
    }

    checkAllIndentItemList() {
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox')
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_indent_list_check').checked;
            }
        }
    }

    submitPickIndentFormSubmit          =   (e)         =>      {
        e.preventDefault();
        let itemDataList                          =    [];
        let requisitionItemRows        =       [];
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    let selectedItemRow = this.state.selectedIndentItemList && this.state.selectedIndentItemList.length > 0 ? this.state.selectedIndentItemList.find(indent => indent.item.id == indentItemForCheckbox[i].getAttribute("data-itemId") )  : null
                   itemDataList.push(selectedItemRow)
                }
            }
        }
        
        if (itemDataList.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        }else {


            itemDataList.map((itemData, indexKey) => {

                let row = { item: itemData.item, qty: itemData.pending_qty, rate: itemData.item && itemData.item.purchase_price ? itemData.item.purchase_price : 0, gst: 0, pending_qty: itemData.pending_qty, indent_id: itemData.transaction_id };
                requisitionItemRows.push(row)
            })
          
          this.setState({
            requisitionItemRows         :       [...this.state.requisitionItemRows,...requisitionItemRows]
        });
         
         this.pickIndentItemModal.hide();
           
        }
    }

    

    itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.requisitionItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    requisitionItemsJsx             =       ()      =>      {
        return (<div className="my-3 row">
            <div className="col-12">
                <table className="table table-bordered table-responsive bg-white">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" style={{ width: "38%" }}>Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Indent</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>GST</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.requisitionItemRows.map((i, k) => {
                            let tdRows = (i.item) ? (<Ax>

                                <td className="text-start">
                                    <div className="">
                                        <div className="row">
                                            <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>{i.item.name}</span></div>
                                            <div className='col-sm-2 text-end'>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu cursor_pointer">
                                                    <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>View Details</li>
                                                    {this.props?.group_permission?.permissions?.includes("isc-item-edit") && <li className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => { this.editItemModalInit(i.item,k) })}}>Edit Item</li>}
                                                    <li className="dropdown-item" onClick={() => { this.addRemark(i.item, k) }}>Add Remark</li>
                                                    <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item, k)}>Consumption History</a></li>
                                                    <li><a href="#" className="dropdown-item" onClick={() => this.viewPOHistoryModalInit(i.item, k)}>PO History</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                        <div className="form-text">
                                            <b>Mfr: </b>{i.item.manufacturer ? i.item.manufacturer.name : '-'} (<b>MPN:</b> {i.item.manufacturer_part_no ? i.item.manufacturer_part_no : '-'})
                                        </div>

                                    </div>
                                    {i.item && i.item.isAddRemark && i.item.isAddRemark == true
                                        ?
                                        <input
                                            name="remark"
                                            type="text"
                                            value={this.state.requisitionItemRows[k].remark}
                                            onChange={(e) => this.onItemInputChange(e, 'remark', k)}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />
                                        : null}

                                </td>
                                <td className='text-center'> {this.state.addRequisitionForm && this.state.addRequisitionForm.warehouse_indent_transaction_id ?
                                        <div className='my-1'>Pending  : <b>{i.pending_qty ? i.pending_qty : 0}</b></div> : "-"}</td>
                                <td className="">
                                <TapInputNumber
                                        name="qty"
                                        value={this.state.requisitionItemRows[k].qty}
                                        onChange={(newValue) => {
                                            const updatedRows = [...this.state.requisitionItemRows];
                                            updatedRows[k].qty = newValue; // Update the quantity in the corresponding row
                                            this.setState({ requisitionItemRows: updatedRows });
                                          }}
                                        placeholder="Qty"
                                        required={true}
                                    />
                                    
                                    <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name || i.item?.unit_name}</span>
                                   
                                </td>
                                <td>
                                <TapInputNumber
                                        name="rate"
                                        value={this.state.requisitionItemRows[k].rate}
                                        onChange={(newValue) => {
                                            const updatedRows = [...this.state.requisitionItemRows];
                                            updatedRows[k].rate = newValue; // Update the quantity in the corresponding row
                                            this.setState({ requisitionItemRows: updatedRows });
                                          }}
                                        placeholder="Unit Rate"
                                        required={true}
                                    />
                                   
                                </td>
                                <td>
                                    <TapSelect
                                        changeEvent={(selectedOption, e) => this.onItemInputChange(e, 'gst', k, selectedOption)}
                                        options={this.state.allGstSlabs}
                                        isSearchable={false}
                                        value={this.state.allGstSlabs.find(s => parseInt(s.value) == parseInt(this.state.requisitionItemRows[k].gst))}
                                        placeholder="GST"
                                    />
                                </td>
                                <td className="text-end">
                                    {(this.state.requisitionItemRows[k].qty * this.state.requisitionItemRows[k].rate).toFixed(2)}
                                </td>
                            </Ax>)
                                : (<Ax>
                                    <td >

                                        <InputItemSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addPurchaseForm', 'item_id');
                                                this.onItemSelect(selectedOption, k)
                                            }}
                                            only_active_item='Y'
                                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                        />

                                    </td>

                                    <td colSpan="5" className="text-center align-middle" >-</td>
                                </Ax>);
                            return (<tr className="" key={k}>
                                <td className="text-center ">{k + 1}</td>
                               {this.state.indent_detail_loading ? <td colSpan={6} className="text-center"><Loader/></td> :  tdRows}
                                <td className="text-center align-middle">
                                    <a href="#" onClick={() => this.removeItemRow(k)}>
                                        <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                    </a>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-12 ">
                <div className='row  '>
                    <div className='col-sm-5 d-flex flex-row' ref={this.convertCurrencyRef}>
                        <span className='mx-2 form-label'>Currency : </span>
                        <TapSelect placeholder="Currency"
                            containerHeight="20px"
                            fontSize="93%"
                            width="80px"
                            className="form-control "
                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addRequisitionForm', 'currency'); this.convertCurrency(selectedOption) }}
                            options={this.state.allCurrencyList}
                            value={this.state.allCurrencyList.find(m => m.value == this.state.addRequisitionForm.currency)}
                        />
                        {this.state.addRequisitionForm.currency == this.state.defaultCurrency ? null
                            : <Ax>
                                (1 {this.state.addRequisitionForm.currency} : {this.state.editCurrency ? <input placeholder="Currency Ratio" className='form-control  mx-2' name="conversion_rate" value={this.state.addRequisitionForm.conversion_rate} onChange={(e) => this.formInputHandler(e, 'addRequisitionForm')} style={{ height: "20px", width: "25%", fontSize: "93%" }} />
                                    : <span className='link-primary mx-2' onClick={() => this.setState({ editCurrency: true })}>{this.state.currency_loading ? <Loader /> : <Ax>{this.state.addRequisitionForm.conversion_rate} {this.state.defaultCurrency}</Ax>}</span>})
                            </Ax>}

                    </div>

                    {this.state.addRequisitionForm && !this.state.addRequisitionForm.warehouse_indent_transaction_id
                        ? <div className='col-sm-7 text-end '>
                            {this.state.addRequisitionForm && this.state.addRequisitionForm.warehouse_id ?
                                <a role="button" onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                                : null}

                            {this.props?.group_permission?.permissions?.includes("isc-item-add") && <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                            <a role="button"
                                onClick={() => {
                                    if (!this.state.addRequisitionForm.warehouse_indent_transaction_id) {
                                        return this.addNewItemRow()
                                    }
                                }}
                                className={['cursor_pointer', this.state.addRequisitionForm.warehouse_indent_transaction_id ? 'disabled' : ''].join(' ')}
                                title={this.state.addRequisitionForm.warehouse_indent_transaction_id ? 'You cannot add new row for Indent Items' : ''}
                                style={{ "pointerEvents": "all" }}
                            >+ Add Row</a>
                            {/* <button type="button" className="btn btn-link text-decoration-none" disabled={this.state.addRequisitionForm.warehouse_indent_transaction_id ? true :false}  onClick={this.addNewItemRow}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </button> */}

                        </div> : null}
                </div>
            </div>
        </div>)
    }

    viewIndentDetailModalJsx                             =   ()  =>  {
        let indent = this.state.indentDetail;
        return (
            <div className="modal fade" id="viewIndentDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="viewIndentDetailModalLabel">Indent : {this.state.indentTransId ? this.state.indentTransId  : "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {!this.state.indent_detail_loading
                                ? (<Ax>
                                    {indent
                                        ?
                                        <div>
                                            <table className="table table-bordered bg-white" >
                                                <thead className="table-secondary">
                                                    <tr>
                                                        <th style={{ width: "33%" }}>ID</th>
                                                        <th style={{ width: "33%" }}>Date</th>
                                                        <th style={{ width: "33%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{indent.transaction_id ? indent.transaction_id : "-"}</td>
                                                        <td scope="col">{indent.transaction_date_display ? indent.transaction_date_display : "-"}</td>
                                                        <td scope="col" className="align-middle">
                                                            {indent.status_data ? indent.status_data.name : ''}
                                                        </td>  </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "33%" }} >Warehouse</td>
                                                        <td colSpan={2}>{indent.warehouse_detail ? <div><b>{indent.warehouse_detail.name} ({indent.warehouse_detail.code})</b> <br />
                                                            {indent.warehouse_detail ?
                                                                <div className='pt-1'>Address : {indent.warehouse_detail.address1 ? indent.warehouse_detail.address1 + ", " : ""}
                                                                    {indent.warehouse_detail.address2 ? indent.warehouse_detail.address2 + ", " : ""}
                                                                    {indent.warehouse_detail.landmark ? indent.warehouse_detail.landmark + ", " : ""}
                                                                    {indent.warehouse_detail.city ? indent.warehouse_detail.city + ". " : "-"} <br />
                                                                    Destination of Supply : <b>{indent.warehouse_detail.state_name}</b> <br />
                                                                    GSTIN  : {indent.warehouse_detail.gstin ? indent.warehouse_detail.gstin : "-"} <br />
                                                                </div> : "-"}</div> : "-"} <br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col">Title</td>
                                                        <th scope="col">{indent.title ? indent.title : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }} >Indent For</td>
                                                        <th>{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "-"} </th>
                                                    </tr>
                                                    {indent.indent_for ?
                                                        <tr>
                                                            <td style={{ width: "33%" }} >{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "null"}</td>
                                                            <th>
                                                                {indent.indent_for == "asset" && indent.assetProfile ? indent.assetProfile.name : indent.indent_for == "workstation" && indent.workstation ? indent.workstation.workstation_name : indent.indent_for == "employee" && indent.employee ? indent.employee.name : "-"}
                                                            </th>
                                                        </tr> : null}
                                                    {indent.additional_attributes_data && indent.additional_attributes_data.length > 0
                                                        ? indent.additional_attributes_data.map((attr) => {
                                                            return (<tr key={attr.key}>
                                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                                <th scope="col">{attr.value ? attr.value : "-"}</th>
                                                            </tr>)
                                                        }) : null}
                                                </tbody>
                                            </table>
                                            <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                                                <thead className="table-secondary">
                                                    <tr className="text-end">
                                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllItem} id="all_check_indent" /></th>
                                                        <th scope="col" className="text-center" style={{ width: "31%" }}>Item Details</th>
                                                        <th scope="col" className="text-center" style={{ width: "8%" }}>Indent Qty</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Fullfilled</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pur. Req</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>PO</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Purchase</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>In Transit </th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        indent && indent?.items && indent?.items?.length > 0 ? indent?.items?.map((i, k) => {
                                                            return (<tr key={k} className="text-end">
                                                                <td className='text-center'>
                                                                    <input
                                                                        type='checkbox'
                                                                        name="foo"
                                                                        value={i?.itemData?.id}
                                                                        data-pendingQty={i.pending_qty}
                                                                        className="indent_item_id_checkbox"
                                                                        data-warehouseId={indent.warehouse_id}
                                                                        data-itemData={i?.itemData}
                                                                        data-transactionId={indent.transaction_id}
                                                                        data-statusLevel={indent.status_data && indent.status_data.level}
                                                                        data-statusIds={indent.status_data && indent.status_data.id}
                                                                    />
                                                                </td>
                                                                <td className="text-start">{i.itemData ? <div className='row'>
                                                                    <div className='col-sm-9'>
                                                                        <ItemViewInList item={i.itemData} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.itemData)} />
                                                                    </div>

                                                                </div> : null}</td>
                                                                <td>{i.indent_qty ? i.indent_qty : 0}</td>
                                                                <td>{i.fullfilled_qty ? i.fullfilled_qty : 0}</td>
                                                                <td>{i.req_qty ? i.req_qty : 0}</td>
                                                                <td>{i.po_qty ? i.po_qty : 0}</td>
                                                                <td>{i.purchase_qty ? i.purchase_qty : 0}</td>
                                                                <td>{i.transit_qty ? i.transit_qty : 0}</td>
                                                                <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                                                <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                                            </tr>);


                                                        })
                                                            : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                            <div className="row align-items-center ">
                                                <div className="col-sm-6">
                                                    <label htmlFor="notes" className="form-label">Notes</label>
                                                    <div>{indent.notes ? indent.notes : "-"}</div>

                                                </div>
                                                <div className=" col-sm-6">

                                                    <table className="table  table-borderless">
                                                        <tbody>

                                                            <tr className="subtotal_bg_color">
                                                                <th>Total Quantity</th>
                                                                <td className='float-end'>{indent.total_qty ? indent.total_qty : 0} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                </Ax>)
                                : <Loader />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    pickIndentItemModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="pickIndentItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="pickIndentItemModalLabel">Pick Item from Approved Indent</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Warehouse   </td>
                                        <th>{this.state.warehouseDetail ? `${this.state.warehouseDetail.name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Indent Transaction ID   </td>
                                        <th>{this.state.addRequisitionForm.warehouse_indent_transaction_id ? this.state.addRequisitionForm.warehouse_indent_transaction_id : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.submitPickIndentFormSubmit} id="submitPickIndentForm">
                            <div className="modal-body">
                                <table className="table table-bordered table-sm align-middle bg-white my-2">
                                    <thead className="align-middle table-secondary">
                                        <tr>
                                            <th className="text-center" style={{ width: "5%" }}><input type="checkbox" onChange={this.checkallItemWiseIndentListing} id="all_indent_list_check" disabled={true} /></th>
                                            <th className="text-center" style={{ width: "10%" }}>Date of Transaction</th>
                                            <th style={{ width: "20%" }}>Item Name</th>
                                            <th style={{ width: "15%" }}>Item Code </th>
                                            <th className='text-end' style={{ width: "10%" }}>Pending Indent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.approved_listing_loading
                                            ? (<tr><td colSpan="6" className="text-center"><Loader /></td></tr>)
                                            :
                                            this.state.selectedIndentItemList && this.state.selectedIndentItemList.length > 0
                                                ? (this.state.selectedIndentItemList.map((rowData, k) => {
                                                    return (<tr key={k}>
                                                        <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                name="indent"
                                                                value={rowData.transaction_id}
                                                                data-itemId={rowData?.item?.id}
                                                                // checked={this.state.requisitionItemRows.find(element => {
                                                                //     if (element?.indent_id == rowData.transaction_id && element?.item?.id == rowData?.item?.id) {
                                                                //         return true;
                                                                //     } else {
                                                                //         return false;
                                                                //     }
                                                                // })}
                                                               
                                                                className="indent_list_id_checkbox"
                                                                disabled={this.state.requisitionItemRows.find(element => {
                                                                    if (element?.indent_id == rowData.transaction_id && element?.item?.id == rowData?.item?.id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })}
                                                            />
                                                        </td>
                                                        <td className='text-center' >{rowData.transaction_date_display ? rowData.transaction_date_display : "-"}</td>
                                                        <td>{rowData.item && rowData.item.name ? rowData.item.name : "-"}</td>
                                                        <td>{rowData.item && rowData.item.item_code ? rowData.item.item_code : "-"}</td>
                                                        <td className='text-end'>{rowData.indent_qty ? <NumberFormatter number={rowData.indent_qty} precision={2} />  : 0}</td>
                                                        
                                                    </tr>);
                                                })
                                                )
                                                : (<tr><td className="text-center" colSpan={6}>No Record Found</td></tr>)
                                        }

                                    </tbody>
                                </table>
                                

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    
    //**********************ADD REQUISTION FORM JSX*******************/

    saveRequisitionFormJsx              = () => {
        
        let item_sub_total                  =   0;
        let item_total_tax                  =   0;
        
        this.state.requisitionItemRows.map((i,k) => {
            if(i.item) {
                let gst                     =   this.state.requisitionItemRows[k].gst ? this.state.requisitionItemRows[k].gst : 0;
                let total                   =   parseFloat(this.state.requisitionItemRows[k].qty * this.state.requisitionItemRows[k].rate);
                item_sub_total              =   parseFloat(item_sub_total) + total;
                let item_tax                =   total * gst / 100 ;    
                item_total_tax              =   item_tax + item_total_tax;    
            }
        });
        
        return (<form className="bg-white p-3" onSubmit={this.addRequisitionFormSubmitEvent}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3"><label htmlFor="site_id" className="form-label ">Date of Purchase Requisition</label></div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.addRequisitionForm.requisition_date ? moment(this.state.addRequisitionForm.requisition_date, 'YYYY-MM-DD').toDate() : false}
                        name="requisition_date"
                        onChange={(value, event) => this.formDateHandler('requisition_date', value, 'addRequisitionForm')}
                        dateFormat="dd-MMM-yyyy"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter  Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addRequisitionForm', 'warehouse_id', () => {
                                this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
                                if (!this.state.change_indent_warehouse) {
                                    this.getAllWarehouseIndentList(selectedOption ? selectedOption.value : null);
                                }
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.addRequisitionForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                        isDisabled={this.state.addRequisitionForm.warehouse_indent_transaction_id && !this.state.change_indent_warehouse ? true : false}
                    />
                </div>
                {this.state.addRequisitionForm.warehouse_indent_transaction_id && <div className="col-sm-2">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={this.state.change_indent_warehouse == "Y" ? "N" : "Y"}
                            checked={this.state.change_indent_warehouse == "Y"}
                            onChange={(e) => { this.setState({ change_indent_warehouse : e.target.value, }); }}
                            id="ChangeIndentWarehouse"
                        />
                        <label className="form-check-label" htmlFor="ChangeIndentWarehouse">
                            Change Warehouse
                        </label>
                    </div>
                </div>}
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRequisitionForm', 'status')}
                        options={this.state.allStatusOptions}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allStatusOptions.find(s => s.value == this.state.addRequisitionForm.status)}
                        placeholder="Select Status"
                    />
                </div>

            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Title</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="title"
                        type="text"
                        value={this.state.addRequisitionForm.title}
                        onChange={(e) => this.formInputHandler(e, 'addRequisitionForm')}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Title"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Priority & Due Date</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRequisitionForm', 'priority')}
                        options={this.state.allPriorityOptions}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allPriorityOptions.find(s => s.value == this.state.addRequisitionForm.priority)}
                        placeholder="Select Priority"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addRequisitionForm.due_date
                                ? moment(this.state.addRequisitionForm.due_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="due_date"
                        onChange={(value, event) => this.formDateHandler('due_date', value, 'addRequisitionForm')}
                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Due  Date`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3"><label htmlFor="Users" className="form-label">Tag Users and Asset</label></div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRequisitionForm', 'users')}
                        options={this.state.allUsers}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allUsers.filter(t => this.state.addRequisitionForm.users.includes(t.value))}
                        placeholder="Please Select User"
                    />
                </div>
                <div className="col-sm-4">
                    <InputAssetSearch
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addRequisitionForm', 'asset_id');
                            this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                        }}
                        placeholder="Search Asset"
                        value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                        search_site_id={this.state.warehouseDetail && this.state.warehouseDetail.sites && this.state.warehouseDetail.sites.length > 0 ? this.state.warehouseDetail.sites.map(st => st.id)  : []}
                    />
                </div>
            </div>
           <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label ">Indent</label>
                    </div>
                    <div className="col-sm-4">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addRequisitionForm', 'warehouse_indent_transaction_id')
                                this.allItemWiseIndentListing(selectedOption ? selectedOption.value : null)
                                this.setState({ requisitionItemRows: [{ item: null }], selectedIndentItemList : [] })
                            }}
                            options={this.state.allIndentList}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            isDisabled={this.state.addRequisitionForm.warehouse_id && !this.state.requisitionDetails?.warehouse_indent?.transaction_id ? false : true}
                            value={this.state.allIndentList.find(s => s.value == this.state.addRequisitionForm.warehouse_indent_transaction_id)}
                            placeholder="Select Indent"
                        />
                    </div>
                    <div className="col-sm-4">
                    <button
                        type="button"
                        disabled={this.state.addRequisitionForm.warehouse_id && this.state.addRequisitionForm.warehouse_indent_transaction_id ? false : true}
                        className="btn btn-link text-decoration-none"
                        onClick={() => this.pickIndentItemModalInit()}
                    ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />  Pick Item from Selected Indent</button>
                </div>

                </div>
               
            {this.requisitionItemsJsx()}
            <div className="row align-items-center mb-5">
                <div className="col-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addRequisitionForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addRequisitionForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                            <tr className="subtotal_bg_color"><th>Sub Total</th><td className="text-end">{item_sub_total.toFixed(2)}</td></tr>
                            <tr className="subtotal_bg_color"><th>Tax</th><td className="text-end">{item_total_tax.toFixed(2)}</td></tr>
                            <tr className="total_bg_color"><th>Total</th><td className="text-end">{(item_sub_total + item_total_tax).toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                {!this.state.requisitionDetails || (this.state.requisitionDetails && this.state.requisitionDetails.status == 1)
                    ? <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2" onClick={(e) => { this.addRequisitionFormSubmitEvent(e, 1); }}>
                        Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    : null
                }
                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2" onClick={(e) => { this.addRequisitionFormSubmitEvent(e); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Add Purchase Requisition</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.requisitionDetails ? "Edit" : "Add"} Purchase Requisition  {this.state.requisitionDetails ? <span> of Transaction ID : {this.state.requisitionDetails?.transaction_id}</span> : null}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {this.state.requisitionFormDataLoaded 
                       ? this.saveRequisitionFormJsx()
                       : <Loader/>}
                    </div>
                </div>
            </div>
            {this.viewIndentDetailModalJsx()}
            {this.pickIndentItemModalJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef} /> 
            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            <ConsumptionHistoryModal ref={this.consumptionHistoryRef}/>
            <POHistoryModal ref={this.poHistoryRef}/>
            {this.state.showItemSearchModal && (<ItemSearchModal ref={this.itemSearchRef}
                afterSelectingItems={(selectedItems) => {
                    let allRows = this.state.storeIndentItemRows ?  this.state.storeIndentItemRows.filter(i => i.item ? true : false) : [];
                    let items = allRows.concat(selectedItems)
                     this.setState({storeIndentItemRows :  items})
                }}
               
                />)}
           
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        group_permission        :   state.app.acl_info,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(RequisitionAdd);