import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';


const TapNavTabs = forwardRef((props, ref) => {


    useEffect(() => {

        onChangeTab(props.activetab || 0);

    }, []);

    const [activetab, setActivetab]         =   useState(null);
    let tabs                                =   props.tabs || [];


    const onChangeTab                       =   (index) => {
        setActivetab(index);
        if(props.setTabValue) {
            props.setTabValue(index);
        }
    } 
    
    return <>
        <ul className="nav nav-tabs bg-white">
            {tabs.map((t, i) => {
                return <li className='nav-item'>
                    <button
                        className={['nav-link', activetab == i && 'active'].join(" ")}
                        type="button"
                        onClick={() => onChangeTab(i)}
                    >{t.name && t.name} </button>
                </li>
            })}
        </ul>
        <div class="tab_content_header mt-0 py-3" style={{paddingBottom: "25px !important"}}>
            {activetab >= 0 && tabs.length > 0 && tabs[activetab] && tabs[activetab].jsx}
        </div> 
    </>

});

export default TapNavTabs;