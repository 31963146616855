import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import DateService from '../../../../services/DateService';
import TapIcon from '../../../../services/TapIcon';
import ItemViewInList from '../../../inventory/includes/ItemViewInList';
import ItemDetailsModal from '../../../items/itemDetails/ItemDetailsModal';
import CurrencyFormatter from '../../../../services/CurrencyFormatter';
import NumberFormatter from '../../../../services/NumberFormater';


const SalesInvoiceDetail = forwardRef((props, ref) => {


    let initVoucherData                     =   {
        transaction_id                          :   '',
        loading                                 :   false,
        data                                    :   null,
    };

    let voucherDetailUrl                    =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/datail";

    
    const access_token                      =   useSelector(state => state.auth.access_token);
    const [voucherData, setVoucherData]     =   useState({...initVoucherData});
    const itemDetailsRef                    =   useRef(null);
    

    

    useEffect(() => {
        if (props.voucher) {
            setVoucherData(pd => ({...pd, loading : false, transaction_id : props.voucher.transaction_id  , data: props.voucher}));
        } else if (props.transaction_id) {
            setVoucherData(pd => ({...pd, loading : false, transaction_id: props.transaction_id}));
            
            initVoucherDetail(props.transaction_id);
        }
    }, []);

    const initVoucherDetail             =   (transaction_id)  =>  {
        setVoucherData(pd => ({...pd, loading : true }));
        let params                      =   {transaction_id : transaction_id };
        HttpAPICall.withAthorization('GET', voucherDetailUrl, access_token, params, {}, (response) => {
            setVoucherData(pd => ({...pd, data : response.data, transaction_id : transaction_id }));
        }).then(() => {
            setVoucherData(pd => ({...pd, loading : false}));
        });
    }

    const viewItemDetailModal                   =   (item)  =>  {
        if(itemDetailsRef.current) {
            itemDetailsRef.current.modalInit(item);
        }
    }

    let item                        =   voucherData && voucherData.data ?  voucherData.data : null;

    return <div>
        {voucherData.loading || !item ? <div className='text-center'><Loader /></div> : <>
            <table className="table table-bordered bg-white">
                <thead className="table-secondary">
                    <tr>
                        <th style={{ width: "33%" }}>ID</th>
                        <th style={{ width: "33%" }}>Date</th>
                        <th style={{ width: "33%" }}>Status</th>
                    </tr>    
                </thead>
                <tbody>
                    <tr>
                        <td>{item.transaction_id}</td>
                        <td>{DateService.dateFormat(item.transaction_date)}</td>
                        <td>{item.status_data ? item.status_data.name : "-"}</td>
                    </tr>
                </tbody>
            </table>
            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                <tbody>
                    <tr>
                        <td style={{ width: "33%" }} >Customer</td>
                        <th colSpan={2}>
                            {item.customer && <div>{item.customer.name}  ({item.customer.code})</div> }
                            {item.customer_billing_address && <div className='fw-normal'>GSTIN : {item.customer_billing_address.gstin}</div> }
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }} >Customer Billing Address</td>
                        <td colSpan={2}>{item.customer_billing_address && <>
                            {item.customer_billing_address.address1 && <span>{item.customer_billing_address.address1}, </span>}
                            {item.customer_billing_address.address2 && <span>{item.customer_billing_address.address2}, <br/></span>}
                            {item.customer_billing_address.landmark && <div>Landmark : {item.customer_billing_address.landmark}, </div>}
                            {item.customer_billing_address.city && <>{item.customer_billing_address.city}, </>}
                            {item.customer_billing_address.district_name && <>{item.customer_billing_address.district_name}, </>}
                            {item.customer_billing_address.state_name && <>{item.customer_billing_address.state_name}, </>}
                            {item.customer_billing_address.country_name && <>{item.customer_billing_address.country_name}</>}
                            {item.customer_billing_address.pincode && <> - {item.customer_billing_address.pincode}</>}
                            </>
                        }</td>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }} >Customer Shipping Address</td>
                        <td colSpan={2}>{item.customer_shipping_address && <>
                            {item.customer_shipping_address.address1 && <span>{item.customer_shipping_address.address1}, </span>}
                            {item.customer_shipping_address.address2 && <span>{item.customer_shipping_address.address2}, <br/></span>}
                            {item.customer_shipping_address.landmark && <div>Landmark : {item.customer_shipping_address.landmark}, </div>}
                            {item.customer_shipping_address.city && <>{item.customer_shipping_address.city}, </>}
                            {item.customer_shipping_address.district_name && <>{item.customer_shipping_address.district_name}, </>}
                            {item.customer_shipping_address.state_name && <>{item.customer_shipping_address.state_name}, </>}
                            {item.customer_shipping_address.country_name && <>{item.customer_shipping_address.country_name}</>}
                            {item.customer_shipping_address.pincode && <> - {item.customer_shipping_address.pincode}</>}
                            </>
                        }</td>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }} >Warehouse</td>
                        <th colSpan={2}>
                            {item.customer && <div>{item.warehouse.name}  ({item.warehouse.code})</div> }
                            {item.warehouse_address && <div className='fw-normal'>GSTIN : {item.warehouse_address.gstin}</div> }
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Tag (Invoice For)</td>
                        <th>{item.invoice_for && item.invoice_for == "asset" ? "Asset" : item.invoice_for && item.invoice_for == "workstation" ? "Workstation" : item.invoice_for && item.invoice_for == "employee" ? "Employee" : item.invoice_for && item.invoice_for == "sub_assembly" ? "Sub Assembly" : "-"}</th>
                        <td>{item.invoice_for == "asset" && item.asset ? item.asset.name : item.invoice_for == "workstation" && item.workstation ? item.workstation.workstation_name : item.invoice_for == "employee" && item.employee ? item.employee.name : item.invoice_for == "sub_assembly" && item.sub_assembly ? item.sub_assembly.name : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Deal</td>
                        <td colSpan={2} >{item.deal_data ? item.deal_data.header : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }} >Voucher Number & Date</td>
                        <td style={{ width: "33%" }} >{item.voucher_number}</td>
                        <td className='text-center' style={{ width: "34%" }} >{item.voucher_date ? DateService.dateFormat(item.voucher_date) : <>-</>}</td>
                    </tr>
                </tbody>
            </table>
            <div className="tab_content_wrapper mt-3 my-1"><span className="content_heading">Item Detail</span></div>
            <div className="table-responsive">
                <table className="table table-hover table-bordered my-1">
                    <thead className="table-secondary">
                        <tr className="">
                            <th style={{ width: "5%" }}>S.No</th>
                            <th className="text-center" style={{ width: "30%" }}>Item Details</th>
                            <th className="text-center" style={{ width: "15%" }}>Batch Number <br />Expiry Date</th>
                            <th className="text-center" style={{ width: "15%" }}>Sales Order <br />Sales Allocation</th>
                            <th className="text-center" style={{ width: "7%" }}>Quantity</th>
                            <th className="text-center" style={{ width: "7%" }}>Unit Rate</th>
                            <th className="text-center" style={{ width: "7%" }}>Discount</th>
                            <th className="text-center" style={{ width: "7%" }}>GST</th>
                            <th className="text-center" style={{ width: "7%" }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    {item && item?.items && item?.items?.length > 0 ? item?.items?.map((i, k) => {
                        return (<tr key={k}>
                            <td className='text-center'>{k + 1}</td>
                            <td>{i.item_profile && <div className='row'>
                                    <div className='col-sm-12'>
                                        <ItemViewInList item={i.item_profile} remark={i.remark} viewItemModal={() => viewItemDetailModal(i.item_profile)} />
                                    </div>
                                </div>}
                            </td>
                            <td>
                                BN : <b> {i.batch_number || ""} </b> <br />
                                ED: <b> {DateService.dateFormat(i.expiry_date) || ""} </b><br />
                            </td>
                            <td>
                                <div>SO : {i.so_transaction_id && i.so_transaction_id}</div>
                                <div>SA : {i.sa_transaction_id && i.sa_transaction_id}</div>
                            </td>
                            <td className='text-end'>
                                {i.qty }
                                {i.item_profile && i.item_profile.unit_name && <span className="text-helping input-group-text text-end pr0">{i.item_profile.unit_name}</span>}
                            </td>
                            <td>{i.rate}</td>
                            <td className='text-end'>{i.discount_rate > 0 ? <div>{i.discount_type == "Fixed" && 'INR'} {i.discount_rate} {i.discount_type == "Percentage" && "%"}</div> : '-'}</td>
                            <td className="text-end">{i.gst > 0 ? (i.gst + ' %') : '-'}</td>
                            <td className="text-end">{parseFloat(i.item_with_discount).toFixed(2)}</td>
                        </tr>)})
                        : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                    }
                    </tbody>
                </table>
            </div>
            <div className="row align-items-center mt-3 mb-5">
                <div className="col-sm-6">
                    <label className="form-label">Notes</label><div>{item.notes || "-"}</div>
                </div>
                <div className="col-sm-6">
                    <table className="table table-borderless subtotal_bg_color">
                        <tbody>
                            <tr className="subtotal_bg_color">
                                <th style={{width:"50%"}}>Sub-Total (INR)</th>
                                <td style={{width:"50%"}} className="text-end">
                                    {item && item.items && item.items.length > 0 &&
                                        <CurrencyFormatter currencyCode="INR" amount={item.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />
                                    }
                                </td>
                            </tr>
                            {item.tax_slabs && item.tax_slabs.length > 0 && (item.tax_slabs.map((ps, k) => {
                                return <tr><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                            }))}
                            <tr className='align-middle'>
                                <th>TCS ({item.tcs_type == "Fixed" && 'INR'} {item.tcs_rate} {item.tcs_type == "Percentage" && "%"} )</th>
                                <th className='text-end'>
                                    <NumberFormatter number={item.tcs_amount} precision={2} />
                                </th>
                            </tr>
                            <tr className="">
                                <th>Adjustment</th>
                                <td className='text-end'>{item.adjustment_amount}</td>
                            </tr>
                            <tr className="align-middle">
                                <th>Final Total (INR)</th>
                                <td className='text-end'><NumberFormatter number={item.total_amount} precision={2} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>}
        <ItemDetailsModal ref={itemDetailsRef} />
    </div>

});

export default SalesInvoiceDetail;