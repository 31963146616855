import { useState } from 'react'

const DataService               =   {

    handleFormState             :   function(state_key, value, stateData, stateFn) {
        let data                    =   {...stateData};
        data[state_key]             =   value;
        stateFn({...data});
    },

    dynamicInputHandlerByKeyVal :   function(object_key, state_key, value, stateData, stateFn) {
        stateData[object_key]         =   {...stateData[object_key] , [state_key] : value};
        stateFn(d => ([...stateData]));
    },





    // dynamicInputHandlerByKeyValOld            =   (e, name, key = 0, state_key = 'addItemForm', afterCallbackFn=null)  =>  {
        // let allRows                 =   this.state[state_key];
        // allRows[key]                =   {...this.state[state_key][key] , [name] : e};
        // this.setState({[state_key] : allRows}, () => {
        //     if(afterCallbackFn) {
        //         afterCallbackFn();
        //     }
        // });
    // }


    
};

export default DataService;