import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Modal } from "bootstrap";
import tapIcon from "../../../services/TapIcon";
import { connect, useSelector } from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import { Link } from "react-router-dom";
import download from "../../../services/DownloadFile";
import { toast } from "react-toastify";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import axios from "axios";

const AttendenceUploadModal = forwardRef(({ loadListingTblData }, ref) => {
  useImperativeHandle(ref, () => ({
    modalHandle,
  }));

  const [
    attendenceBulkUploadFormSubmitting,
    setAttendenceBulkUploadFormSubmitting,
  ] = useState(false);
  const attendenceBulkUploadModalRef = useRef(null);
  const access_token = useSelector((state) => state.auth.access_token);
  useEffect(() => {
    attendenceBulkUploadModalRef.current = new Modal(
      document.getElementById("attendenceBulkUploadModal"),
      { keyboard: false, backdrop: false }
    );
  }, []);

  const modalHandle = () => {
    attendenceBulkUploadModalRef.current.show();
  };

  const downloadSampleFile = () => {
    HttpAPICall.withAthorization("GET", `${HRM_API_BASE_URL_2}/attendance/sample_download`, access_token, {}, {}, (response) => {
        download.file(response.data.file_path);
        toast.success("Employee attendence Sample Excel File Downloaded Successfully",{position: toast.POSITION.TOP_RIGHT});
        attendenceBulkUploadModalRef.current.hide();
      }
    );
  };
  const formSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(document.getElementById("attendenceBulkUploadForm"));
    let headers = {Accept: "application/json", "Content-Type": "multipart/form-data", Authorization: `Bearer ${access_token}`};
    axios.post(`${HRM_API_BASE_URL_2}/attendance/add_by_excel`, data, {
      headers: headers,
    }).then((response) => {
      toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT});
      attendenceBulkUploadModalRef.current.hide();
      //Load Add Item Form Data :-
      download.file(response.data.generated_file.file_path);
        loadListingTblData(1);
    })
    .catch((err) => {
        toast.error(err?.response?.data?.message ?? "Unable to upload attendence Add File.", { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {
        setAttendenceBulkUploadFormSubmitting(false);
      });
  };

  return (
    <div className="modal fade" id="attendenceBulkUploadModal" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="attendenceBulkUploadModalLabel">
              Attendence Upload
            </h5>
            <button
              type="button"
              disabled={attendenceBulkUploadFormSubmitting}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form
            onSubmit={formSubmit}
            id="attendenceBulkUploadForm"
            method="post"
            encType="multipart/form-data"
          >
            <div className="modal-body">
              <div className="row align-items-center mb-3 mt-3">
                <div className="col-12">
                  <input
                    name="document"
                    type="file"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-12 mt-2 form-text">
                  # Excel Sheet with Extension xls, xlm, xlsx, ods Allowed
                </div>
                <div className="col-12 mt-2 form-text">
                  # Maximum 100 MB Size of Excel Sheet Allowed
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div>
                <Link
                  type="button"
                  className="float-left"
                  href="#"
                  onClick={downloadSampleFile}
                >
                  Download Sample File
                </Link>
              </div>
              <div>
                <button
                  type="button"
                  disabled={attendenceBulkUploadFormSubmitting}
                  className="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={attendenceBulkUploadFormSubmitting}
                  className="btn btn-primary"
                >
                  Submit{" "}
                  {attendenceBulkUploadFormSubmitting && (
                    <tapIcon.FontAwesomeIcon
                      icon={tapIcon.faSyncAlt}
                      className="fa-spin"
                    />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default AttendenceUploadModal;
