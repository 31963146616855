
import {
    ReportList,
    ShiftwiseAssetRunningWithOT, ServiceChecksheetRunningStatus,
    InvoiceCalculation,
    DailyProgressReport,
    AssetPerformanceMatrix, AssetStatus, WalletLedgerList, ClosingStock,
    ItemWiseConsumption, ItemWiseTransactionLedger, ItemWiseRequisition, ItemWisePurchase, WalletSummaryReport, InventoryMovementLedger,
    InventoryForecastingReport, ComplianceReport, AssetRental, DateWiseFuelFeedIn, FuelStationLedger,
    FuelStationSummary, AssetWiseComplianceReport, AssetData, ItemWiseAllocation, VendorWisePurchaseReport, AssetWiseDocument,
    ActivityWiseTaskReport,
    ServiceChecksheetExecutionHistoryReport,
    DateWiseTaskReport,
    AssetWiseScsReport,
    ScsScore,
    ItemWiseInventoryTransfer,
    ItemWiseWarehouseIndent,
    TaskSAPReportList,
    ItemWiseInventoryAdjustment,
    ItemWiseFreeStock,
    EmployeeWiseComplianceReport,
    FuelConsumption,
    AssetSegerationReport,
    EmployeeWorkingReport,
    AssetComparisonReport,
    SiteWiseProductivityReport,
    FuelVendorWisePurchase,
    DateWiseProductivityReport,
    ItemWiseLeadTimeReport,
    FuelReceiver,
    SiteComparisonReport,
    PeriodWiseSiteComparisonReport,
    WorkstationWiseProductivityReport,
    ItemWisePurchaseReturn,
    ShiftWiseProductivityReport,
    SainikFuelIssueReport,
    MeanTimeRepairReport,
    ShiftWiseAssetProductivityAnalysis,
    AssetWiseEmployeeWorking,
    ItemWiseDemandReport,
    DateWiseAnalysisReport,
    ItemWiseQuotationReport,
} from '../pages/reports/Index';

import ItemWisePurchaseOrder from '../pages/reports/inventoryReports/ItemWisePurchaseOrder';
import StockMovementWarehouseReport from '../pages/reports/inventoryReports/StockMovementWarehouseReport';
import StockMovementItemReport from '../pages/reports/inventoryReports/StockMovementItemReport';
import ShiftWiseCounterlogReport from '../pages/reports/asset/ShiftWiseCounterlogReport';
import ProductivityComparisonReport from '../pages/reports/productivity/ProductivityComparisonReport';
import AssetWiseFuelProductionReport from '../pages/reports/productivity/AssetWiseFuelProductionReport';
import SiteWiseFuelProductionReport from '../pages/reports/productivity/SiteWiseFuelProductionReport';
import AssetWiseItemTrackingReport from '../pages/reports/asset/AssetWiseItemTrackingReport';
import ShiftWiseFuelIssue from '../pages/reports/fuel/ShiftWiseFuelIssue';
import AssetProductivityAnalysis from '../pages/reports/asset/AssetProductivityAnalysis';
import AssetDataScoreReport from '../pages/reports/asset/AssetDataScoreReport';
import ServiceChecksheetDueReport from '../pages/reports/asset/ServiceChecksheetDueReport';
import AssetMTTRMTBFReport from '../pages/reports/asset/AssetMTTRMTBFReport';
import Reportdata from '../pages/reports/Reportdata';

import ScheduleReport from '../pages/reports/ScheduleReport';
import ReportRequest from '../pages/reports/ReportRequest';
import ItemWiseSalesAllocation from '../pages/reports/sales/ItemWiseSalesAllocation';
import ItemWiseSalesInvoice from '../pages/reports/sales/ItemWiseSalesInvoice';
import ItemWiseSalesOrder from '../pages/reports/sales/ItemWiseSalesOrder';
import HGFuelFeedInReport from '../pages/reports/asset/HGInfraReports/HGFuelReportList';

const routes = [
    { path: '/report', exact: true, name: "Reports", component: ReportList },
    { path: '/report/asset_invoice_calculator', exact: true, name: "Asset Invoice Calculator", component: InvoiceCalculation },
    { path: '/report/shiftwise_asset_running_with_ot', exact: true, name: "Shiftwise Asset Running with OT Calculations", component: ShiftwiseAssetRunningWithOT },
    { path: '/report/asset_performance_matrix', exact: true, name: "Asset Performance Matrix", component: AssetPerformanceMatrix },
    { path: '/report/asset_productivity_analysis', exact: true, name: "Asset Productivity Analysis", component: AssetProductivityAnalysis },
    { path: '/report/asset_status', exact: true, name: "Asset Status", component: AssetStatus },
    { path: '/report/wallet_ledger', exact: true, name: "Wallet Ledger", component: WalletLedgerList },
    { path: '/report/item_closing_stock', exact: true, name: "Closing Stock", component: ClosingStock },
    { path: '/report/item_wise_consumption', exact: true, name: "Item Wise Consumption", component: ItemWiseConsumption },
    { path: '/report/item_stock_transaction_ledger', exact: true, name: "ItemWise Transaction Ledger", component: ItemWiseTransactionLedger },
    { path: '/report/item_wise_requisition', exact: true, name: "ItemWise Requisition", component: ItemWiseRequisition },
    { path: '/report/item_wise_purchase', exact: true, name: "ItemWise Purchase", component: ItemWisePurchase },
    { path: '/report/wallet_summary', exact: true, name: "Wallet Summary", component: WalletSummaryReport },
    { path: '/report/item_wise_purchase_order', exact: true, name: "ItemWise Purchase Order", component: ItemWisePurchaseOrder },
    { path: '/report/inventory_movement_ledger', exact: true, name: "Inventory Movement  Ledger", component: InventoryMovementLedger },
    { path: '/report/service_checksheet_running_status', exact: true, name: "Service Checksheet Running Status", component: ServiceChecksheetRunningStatus },
    { path: '/report/daily_status_report', exact: true, name: "Daily Progress Report", component: DailyProgressReport },
    { path: '/report/inventory_forecasting_report', exact: true, name: "Inventory Forecasting Report", component: InventoryForecastingReport },
    { path: '/report/compliance_report', exact: true, name: "Compliance Report", component: ComplianceReport },
    { path: '/report/asset_rental', exact: true, name: "Asset Rental", component: AssetRental },
    { path: '/report/date_wise_fuelfeedin', exact: true, name: "Date Wise Fuel Feed In", component: DateWiseFuelFeedIn },
    { path: '/report/fuel_station_ledger', exact: true, name: "Fuel Station Ledger", component: FuelStationLedger },
    { path: '/report/fuel_station_summary', exact: true, name: "Fuel Station Summary", component: FuelStationSummary },
    { path: '/report/asset_wise_compliance_report', exact: true, name: "Asset Wise Compliance", component: AssetWiseComplianceReport },
    { path: '/report/date_wise_fuel_feedin', exact: true, name: "Date Wise Fuel FeedIn", component: DateWiseFuelFeedIn },
    { path: '/report/asset_data', exact: true, name: "Asset Data", component: AssetData },
    { path: '/report/item_wise_allocation', exact: true, name: "Item Wise Allocation", component: ItemWiseAllocation },
    { path: '/report/vendor_wise_purchase_order', exact: true, name: "Vendor Wise Purchase Order", component: VendorWisePurchaseReport },
    { path: '/report/asset_wise_document', exact: true, name: "Asset Wise Document", component: AssetWiseDocument },
    { path: '/report/activity_wise_task_report', exact: true, name: "Activity Wise Task Report", component: ActivityWiseTaskReport },
    { path: '/report/service_checksheet_execution_history', exact: true, name: "SCS Execution History & Analysis Report", component: ServiceChecksheetExecutionHistoryReport },
    { path: '/report/date_wise_task_report', exact: true, name: "Date Wise Task Report", component: DateWiseTaskReport },
    { path: '/report/asset_wise_scs_report', exact: true, name: "Asset Wise SCS Report", component: AssetWiseScsReport },
    { path: '/report/service_checksheet_score_report', exact: true, name: "SCS Score Report", component: ScsScore },
    { path: '/report/item_wise_inventory_transfer', exact: true, name: "Item Wise Invent Report", component: ItemWiseInventoryTransfer },
    { path: '/report/item_wise_warehouse_indent', exact: true, name: "Item Wise Warehouse Indent Report", component: ItemWiseWarehouseIndent },
    { path: '/report/schedule_report', exact: true, name: " Report", component: ScheduleReport },
    { path: '/report/download_request', exact: true, name: " Report", component: ReportRequest},
    { path: '/report/hg_task_sap_report', exact: true, name: " Report", component: TaskSAPReportList },
    { path: '/report/hg_fuel_report', exact: true, name: " Report", component: HGFuelFeedInReport },
    { path: '/report/item_wise_inventory_adjustment', exact: true, name: "Item Wise Inventory Adjustment Report", component: ItemWiseInventoryAdjustment },
    { path: '/report/item_wise_free_stock', exact: true, name: "Item Wise Current Stock Report", component: ItemWiseFreeStock },
    { path: '/report/stock_movement_warehouse_wise', exact: true, name: "Stock Movement Warehouse Wise", component: StockMovementWarehouseReport },
    { path: '/report/stock_movement_item_wise', exact: true, name: "Stock Movement Item Wise", component: StockMovementItemReport },
    { path: '/report/employee_compliance_due_report', exact: true, name: "Employee Wise Compliance", component: EmployeeWiseComplianceReport },
    { path: '/report/fuel_consumption_report', exact: true, name: "Fuel Consumption Report", component: FuelConsumption },
    { path: '/report/shift_wise_fuel_issue', exact: true, name: "Shift wise Fuel Issue Report", component: ShiftWiseFuelIssue },
    { path: '/report/shift_wise_counterlog_report', exact: true, name: "Shift wise Counterlog Report", component: ShiftWiseCounterlogReport },
    { path: '/report/asset_utilization_report', exact: true, name: "Asset Segregation Report", component: AssetSegerationReport },
    { path: '/report/employee_working_report', exact: true, name: "Employee Working Report", component: EmployeeWorkingReport },
    { path: '/report/asset_comparison_report', exact: true, name: "Asset Comparison Report", component: AssetComparisonReport },
    { path: '/report/fuel_vendor_wise_purchase_report', exact: true, name: "Fuel Vendor Wise Purchase Report", component: FuelVendorWisePurchase },
    { path: '/report/fuel_receiver_report', exact: true, name: "Fuel Receiver Report", component: FuelReceiver },
    { path: '/report/productivity_period_comparison_report', exact: true, name: "Productivity Comparison Report", component: ProductivityComparisonReport },
    { path: '/report/site_wise_productivity_report', exact: true, name: "Site Wise Productivity Report", component: SiteWiseProductivityReport },
    { path: '/report/date_wise_productivity_report', exact: true, name: "Date Wise Productivity Report", component: DateWiseProductivityReport },
    { path: '/report/asset_wise_fuel_productivity_report', exact: true, name: "Asset Wise Fuel Productivity Report", component: AssetWiseFuelProductionReport },
    { path: '/report/site_wise_fuel_productivity_report', exact: true, name: "Site Wise Fuel Productivity Report", component: SiteWiseFuelProductionReport },
    { path: '/report/item_wise_lead_time_report', exact: true, name: "Item Wise Lead Time Report", component: ItemWiseLeadTimeReport },
    { path: '/report/site_comparison_report', exact: true, name: "Site Comparison Report", component: SiteComparisonReport },
    { path: '/report/period_wise_site_comparison_report', exact: true, name: "Site Comparison Report", component: PeriodWiseSiteComparisonReport },
    { path: '/report/asset_wise_item_tracking', exact: true, name: "Asset Wise Item Tracking", component: AssetWiseItemTrackingReport },
    { path: '/report/workstation_wise_productivity', exact: true, name: "Workstation Wise Productivity", component: WorkstationWiseProductivityReport },
    { path: '/report/item_wise_purchase_return', exact: true, name: "Item wise Purchase Return", component: ItemWisePurchaseReturn },
    { path: '/report/shift_wise_productivity', exact: true, name: "Shift Wise Productivity", component: ShiftWiseProductivityReport },
    { path: '/report/smasl_fuel_report_for_sap', exact: true, name: "Fuel Report for SAP (SMASL)", component: SainikFuelIssueReport },
    { path: '/report/mean_time_repair_report', exact: true, name: "MeanTimeRepairReport", component: MeanTimeRepairReport },
    { path: '/report/shift_wise_asset_running', exact: true, name: "Shift Wise Asset Running Report", component: ShiftWiseAssetProductivityAnalysis },
    { path: '/report/employee_performance_report', exact: true, name: "Employee Peformance Report", component: AssetWiseEmployeeWorking },
    { path: '/report/item_wise_demand_report', exact: true, name: "Item Wise Demand Report", component: ItemWiseDemandReport },
    { path: '/report/asset_data_score', exact: true, name: "Asset Data Score Report", component: AssetDataScoreReport },
    { path: '/report/service_checksheet_due_report', exact: true, name: "Service Checksheet Due Report", component: ServiceChecksheetDueReport },
    { path: '/report/date_wise_running_with_analysis', exact: true, name: "Date Wise Asset Running Report", component: DateWiseAnalysisReport },
    { path: '/report/item_wise_quotation_report', exact: true, name: "Item Wise Quotation Report", component: ItemWiseQuotationReport },
    { path: '/report/list', exact: true, name: "Date Wise Asset Running Report", component: Reportdata },
    { path: "/report/asset_mttr_mtbf_report", exact: true, name: "Asset MTTR & MTBF Report", component: AssetMTTRMTBFReport, },
    { path: '/report/item_wise_sales_allocation', exact: true, name: "Item wise Sales Allocation", component: ItemWiseSalesAllocation },
    { path: '/report/item_wise_sales_invoice', exact: true, name: "Item wise Sales Invoice", component: ItemWiseSalesInvoice },
    { path: '/report/item_wise_sales_order', exact: true, name: "Item wise Sales Order", component: ItemWiseSalesOrder },
    { path: '/report/shift_wise_productivity', exact: true, name: "Shift Wise Productivity", component: ShiftWiseProductivityReport},
    { path: '/report/smasl_fuel_report_for_sap', exact: true, name: "Fuel Report for SAP (SMASL)", component: SainikFuelIssueReport},
    { path: '/report/mean_time_repair_report', exact: true, name: "MeanTimeRepairReport", component: MeanTimeRepairReport},
    { path: '/report/shift_wise_asset_running', exact: true, name: "Shift Wise Asset Running Report", component: ShiftWiseAssetProductivityAnalysis},
    { path: '/report/employee_performance_report', exact: true, name: "Employee Peformance Report", component: AssetWiseEmployeeWorking},
    { path: '/report/item_wise_demand_report', exact: true, name: "Item Wise Demand Report", component: ItemWiseDemandReport},
    { path: '/report/asset_data_score', exact: true, name: "Asset Data Score Report", component: AssetDataScoreReport},
    { path: '/report/service_checksheet_due_report', exact: true, name: "Service Checksheet Due Report", component: ServiceChecksheetDueReport},
    { path: '/report/date_wise_running_with_analysis', exact: true, name: "Date Wise Asset Running Report", component: DateWiseAnalysisReport},
    { path: '/report/item_wise_quotation_report', exact: true, name: "Item Wise Quotation Report", component: ItemWiseQuotationReport},
];

export default routes;