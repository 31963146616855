import React from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";

import Ax from "../../../components/hoc/Ax";

import {
  DisplayListPagination,
  DisplaySearchCriteria,
  PeriodSelectionWithCustom,
} from "../../../components/TapUi";

import ApplicationLayout from "../../../layouts/ApplicationLayout";

import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TapApiUrls, { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import moment from "moment";
import DatePicker from "react-datepicker";
import DowntimeView from "./DowntimeView";
import AssetCard from "../../includes/assetCard/AssetCard";
import DownloadFile from "../../../services/DownloadFile";
import swal from "sweetalert";

class DowntimeList extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.downtimeSearchInitState = {
      search_transaction_id: "",
      search_asset_ids: [],
      asset_group_ids: [],
      search_asset_type_ids: [],
      site_group_ids: [],
      search_site_ids: [],
      search_downtime_types: [],
      search_section_ids: [],
      search_sub_section_ids: [],
      search_downtime_from: "",
      search_downtime_to: "",
      search_defect_codes: [],
      date_range: "",
      date_range_start: null,
      date_range_end: null,
    };

    this.downtimeSearchFormLabel = {
      search_transaction_id: "Transaction ID : ",
      search_asset_ids: "Asset : ",
      asset_group_ids: "Asset Type Groups : ",
      search_asset_type_ids: "Asset Type : ",
      site_group_ids: "Site Groups : ",
      search_site_ids: "Site : ",
      search_downtime_types: "Type of Downtime : ",
      search_section_ids: "Section : ",
      search_downtime_from: "Downtime Duration more than Hr : ",
      search_downtime_to: "Downtime Duration less than Hr : ",
      search_sub_section_ids: "Sub Section : ",
      search_defect_codes: "Defect Codes : ",
      date_range: "Transaction Period : ",
    };

    this.state = {
      minimizeTable: false,
      listing_loading: false,
      downtimeDownloading: false,
      listing_tbl_page: 1,
      counterlog_listing: [],
      listingMeta: null,
      totalListingCount: 0,
      downtimeData: null,
      viewDataLoading: false,
      access_token: "",
      all_periods: [],
      downtimeSearchForm: { ...this.downtimeSearchInitState },
      formSearchedElems: [],
      downtimeSearchParams: null,
      searchedAssets: [],
      iam_asset_types: [],
      iam_group_sites: [],
      allDowntimeTypes: [],
      allSectionList: [],
      allSubSectionList: [],
      allSiteGroupsList: [],
      allAssetTypesGroupsList: [],
      allDefectCodes: [],
      downtimeviewData: null,
    };

    this.downtimeList     = IAM_API_BASE_URL_2 + '/downtime/list';
    this.downtimeFormData = IAM_API_BASE_URL_2 + '/downtime/list_form_data';

  }

  componentDidMount() {
    // this.setState({access_token : this.props.access_token})
    this.loadListingTblData(1);
    this.initalizeSearchFormData(this.props);
    this.downtimeSearchModal = new Modal(
      document.getElementById("downtimeSearchModal"),
      { keyboard: false, backdrop: false }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iam_asset_types !== this.props.iam_asset_types) {
      // this.setState({access_token : nextProps.access_token})
      this.initalizeSearchFormData(nextProps);
    }
  }

  initalizeSearchFormData = (props) => {
    let all_periods = [
      ...props.all_periods,
      { key: "custom", display: "Custom Date Range" },
    ];

    HttpAPICall.withAthorization(
      "GET",
      this.downtimeFormData,
      this.props.access_token,
      {}
    ).then((response) => {
      let respData = response.data;

      this.setState({
        iam_asset_types: props.iam_asset_types
          ? props.iam_asset_types.map((at) => {
              return { value: at.id, label: at.asset_type_name };
            })
          : [],
        all_periods: all_periods,
        allDefectCodes:
          response.data &&
          response.data.defect_codes &&
          response.data.defect_codes.length > 0
            ? response.data.defect_codes.map((dt, k) => {
                return { value: dt.id, label: dt.name };
              })
            : [],
        iam_group_sites: props.iam_group_sites
          ? props.iam_group_sites.map((s) => {
              return { value: s.id, label: `${s.site_name} (${s.site_code})` };
            })
          : [],
        allDowntimeTypes:
          respData &&
          respData.downtime_types &&
          respData.downtime_types.length > 0
            ? respData.downtime_types.map((at) => {
                return { value: at.id, label: at.name };
              })
            : [],
        allSectionList:
          response.data.sections && response.data.sections.length > 0
            ? response.data.sections.map((section) => {
                return {
                  value: section.id,
                  label: section.component,
                  subcomponents: section.subcomponents,
                };
              })
            : [],
        allSiteGroupsList:
          props.site_groups && props.site_groups.length > 0
            ? props.site_groups.map((oc) => {
                return {
                  value: oc.id,
                  label: oc.name,
                  linked_sites: oc.site_ids,
                };
              })
            : [],
        allAssetTypesGroupsList:
          props.asset_groups && props.asset_groups.length > 0
            ? props.asset_groups.map((oc) => {
                return {
                  value: oc.id,
                  label: oc.name,
                  linked_asset_types: oc.asset_type_ids,
                };
              })
            : [],
      });
    });
  };

  loadListingTblData = (page = 1) => {
    this.setState({ listing_loading: true, listing_tbl_page: page });
    let params = {
      page: page,
      need_asset_profile: "Y",
      need_counterlog: "Y",
      need_section: "Y",
      per_page: 50,
      ...this.state.downtimeSearchParams,
    };
    HttpAPICall.withAthorization(
      "GET",
      this.downtimeList,
      this.state.access_token
        ? this.state.access_token
        : this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          downtime_listing: respData.data,
          listingMeta: respData.meta,
          totalListingCount: respData.meta.total,
        });
      }
    ).then(() => this.setState({ listing_loading: false }));
  };

  viewDowntimeDetail = (id) => {
    this.setState({ minimizeTable: true });
    this.getDowntimeDetail(id);
  };

  closeView = () => {
    this.setState({ minimizeTable: false });
  };

  getDowntimeDetail = (downtime_id = "") => {
    let total_hours = "00:00";
    this.setState({ viewDataLoading: true });
    HttpAPICall.withAthorization(
      "GET",
      TapApiUrls.IAM + "/downtime/detail",
      this.props.access_token,
      {
        transaction_id: downtime_id,
        need_section: "Y",
        need_defect_codes: "Y",
        need_counterlog: "Y",
        need_site: "Y",
        need_asset_profile: "Y",
      },
      {},
      (response) => {
        let respData = response.data;
        this.setState(
          {
            downtimeViewData: respData.data,
          },
          () => {}
        );
      },
      (error) => {
        toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    ).then(() => this.setState({ viewDataLoading: false }));
  };

  downtimeSearchModalInit = () => {
    this.downtimeSearchModal.show();
  };

  downtimeSearchClear = () => {
    this.setState(
      {
        formSearchedElems: [],
        downtimeSearchParams: null,
        searchedAssets: [],
        downtimeSearchForm: { ...this.downtimeSearchInitState },
      },
      () => {
        this.downtimeSearchModal.hide();
        this.loadListingTblData(1);
      }
    );
  };

  downtimeSearchFormSubmit = (event = null) => {
    event && event.preventDefault();
    //Get All Keys :-
    let serachFormKeys = Object.keys(this.state.downtimeSearchForm);
    let searchedElems = [];
    let downtimeSearchParams = {};
    serachFormKeys.map((key) => {
      let label = this.downtimeSearchFormLabel[key];
      let value = this.state.downtimeSearchForm[key];

      if (value && value !== "Invalid date" && value.toString().length > 0) {
        downtimeSearchParams[key] = value;
        if (label) {
          let show_val = value;
          if (key == "search_asset_ids") {
            show_val =
              this.state.searchedAssets && this.state.searchedAssets.length > 0
                ? this.state.searchedAssets
                    .map((s) => s.display_label)
                    .join(", ")
                : "";
          }
          if (key == "asset_group_ids") {
            show_val = this.state.allAssetTypesGroupsList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_asset_type_ids") {
            show_val = this.state.iam_asset_types
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }

          if (key == "search_transaction_id") {
            show_val =
              this.state.downtimeSearchForm &&
              this.state.downtimeSearchForm.search_transaction_id
                ? this.state.downtimeSearchForm.search_transaction_id
                : "";
          }
          if (key == "site_group_ids") {
            show_val = this.state.allSiteGroupsList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_site_ids") {
            show_val = this.state.iam_group_sites
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_downtime_types") {
            show_val = this.state.allDowntimeTypes
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_section_ids") {
            show_val = this.state.allSectionList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_defect_codes") {
            show_val = this.state.allDefectCodes
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_sub_section_ids") {
            show_val = this.state.allSubSectionList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_downtime_from") {
            show_val =
              this.state.downtimeSearchForm &&
              this.state.downtimeSearchForm.search_downtime_from
                ? moment(
                    this.state.downtimeSearchForm.search_downtime_from
                  ).format("HH:mm")
                : "";
          }
          if (key == "search_downtime_to") {
            show_val =
              this.state.downtimeSearchForm &&
              this.state.downtimeSearchForm.search_downtime_to
                ? moment(
                    this.state.downtimeSearchForm.search_downtime_to
                  ).format("HH:mm")
                : "";
          }

          if (key == "date_range") {
            let start_range = moment(
              this.state.downtimeSearchForm.date_range_start
            ).format("DD-MMM-YYYY");
            let end_range = moment(
              this.state.downtimeSearchForm.date_range_end
            ).format("DD-MMM-YYYY");
            let display_custom = `Custom Date Range (${start_range} - ${end_range})`;
            show_val = this.state.all_periods
              .filter((s) => value.includes(s.key))
              .map((s) =>
                s.display_with_date ? s.display_with_date : display_custom
              );
          }
          searchedElems.push(label + `<b>${show_val}</b>`);
        }
      }
    });
    //Generate Html :-
    this.setState(
      {
        formSearchedElems: searchedElems,
        downtimeSearchParams: {
          ...downtimeSearchParams,
          search_downtime_from: this.state.downtimeSearchForm
            .search_downtime_from
            ? moment(this.state.downtimeSearchForm.search_downtime_from).format(
                "HH:mm"
              )
            : "",
          search_downtime_to: this.state.downtimeSearchForm.search_downtime_to
            ? moment(this.state.downtimeSearchForm.search_downtime_to).format(
                "HH:mm"
              )
            : "",
        },
      },
      () => {
        this.downtimeSearchModal.hide();
        this.loadListingTblData(1);
      }
    );
  };

  SecondsToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  }

  downtimeDownloadHandle = () => {
    let params = { action: "download", ...this.state.downtimeSearchParams };
    if (this.state.downtimeSearchParams !== null) {
      this.downtimeDownload(params);
    } else {
      swal({
        title: "Download",
        text: "The download will be for Current Quarter's Transaction, in case you want different Transaction Period then please change by filter.",
        icon: "warning",
        buttons: ["Cancel", "Okay"],
      }).then((willDownload) => {
        if (willDownload) {
          params = { ...params, date_range: "current_quarter" };
          this.downtimeDownload(params);
        }
      });
    }
  };
  downtimeDownload = (params) => {
    this.setState({ downtimeDownloading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.downtimeList,
      this.state.access_token
        ? this.state.access_token
        : this.props.access_token,
      params,
      {},
      (resp) => {
        DownloadFile.file(resp.data.file_path);
      }
    ).then(() => this.setState({ downtimeDownloading: false }));
  };
  //***********************Downtime JSX****************

  downtimeListjsx = () => {
    return (
      <Ax>
        <div className="page_title row m0">
          <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
            <h3>Downtime List</h3>
            <div className="text-end mt15">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  this.state.downtimeDownloading || this.state.listing_loading
                }
                onClick={this.downtimeDownloadHandle}
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                {this.state.downtimeDownloading ? (
                  <tapIcon.FontAwesomeIcon
                    icon={tapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
              <button
                type="button"
                disabled={this.state.listing_loading}
                onClick={this.downtimeSearchModalInit}
                className="btn btn-secondary"
              >
                <tapIcon.imageIcon
                  icon={tapIcon.SearchIcon}
                  className="img-fluid"
                  alt="item-action"
                />
              </button>
            </div>
          </div>
          {this.state.minimizeTable ? (
            <div className="col-9 tap_border_left">
              <h3 className="text-capitalize">
                Downtime : {this.state.downtimeViewData?.transaction_id}
              </h3>
              <div className="text-end mt15">
                <button
                  onClick={this.closeView}
                  type="button"
                  className="btn btn-secondary"
                >
                  <tapIcon.imageIcon
                    icon={tapIcon.CloseIcon}
                    className="img-fluid"
                    alt="Downtime-close"
                  />
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="container-fluid pl5">
          <div className="page_containt row">
            <div
              className={[
                "pageTbl",
                this.state.minimizeTable ? "col-3" : "col-12",
              ].join(" ")}
            >
              {this.downtimeListingTableJsx()}
            </div>
            {this.state.minimizeTable ? (
              <div className="col-9 pr4 pl4 bg-white ">
                {this.state.viewDataLoading ? (
                  <Loader />
                ) : (
                  <div
                    className="tab_content_header"
                    style={{ height: "80vh" }}
                  >
                    {this.downtimeViewJsx()}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Ax>
    );
  };

  downtimeListingTableJsx = () => {
    return (
      <Ax>
        <DisplaySearchCriteria
          searchedElems={this.state.formSearchedElems}
          onClearFilter={this.downtimeSearchClear}
        />
        <table className="table table-bordered table-fixed  bg-white table-sm">
          <thead className="table-secondary">
            {this.state.minimizeTable ? (
              <tr>
                <th scope="col" style={{ width: "15%" }}>
                  Downtime
                </th>
              </tr>
            ) : (
              <tr>
                <th scope="col" style={{ width: "5%" }} className="text-center">
                  S.No
                </th>
                <th
                  scope="col"
                  style={{ width: "12%" }}
                  className="text-center"
                >
                  Date
                </th>
                <th scope="col" style={{ width: "14%" }}>
                  Transaction ID
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Asset
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Asset Type
                </th>
                <th scope="col" style={{ width: "14%" }}>
                  Type of Downtime
                </th>
                <th
                  scope="col"
                  style={{ width: "10%" }}
                  className="text-center"
                >
                  Downtime Hours
                </th>
                <th scope="col" style={{ width: "12%" }}>
                  {" "}
                  Counter Log
                </th>
                <th scope="col" style={{ width: "5%" }}>
                  Action
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {this.state.listing_loading ? (
              <tr>
                <td colSpan="9">
                  <Loader />
                </td>
              </tr>
            ) : this.state.downtime_listing &&
              this.state.downtime_listing.length > 0 ? (
              this.state.downtime_listing.map((data, index) => {
                return (
                  <tr key={index}>
                    {this.state.minimizeTable ? (
                      <Ax>
                        <td style={{ width: "25%" }}>
                          <div
                            className="text-capitalize link-primary cursor_pointer"
                            onClick={() =>
                              this.viewDowntimeDetail(data.transaction_id)
                            }
                          >
                            {data.transaction_id ? data.transaction_id : "-"}
                          </div>
                          <div className="mt-1">
                            <small className="text-capitalize ">
                              {data.asset && data.asset.name
                                ? data.asset.name
                                : "-"}{" "}
                            </small>
                            <small className="float-end">
                              {data.downtime_type
                                ? data.downtime_type.name
                                : "-"}
                            </small>
                          </div>
                        </td>
                      </Ax>
                    ) : (
                      <Ax>
                        <td className="text-center">
                          {this.state.listingMeta
                            ? this.state.listingMeta.from + index
                            : index}
                        </td>
                        <td>{data.feed_datetime ? data.feed_datetime : "-"}</td>
                        <td
                          className={[
                            this.props.permissions.includes("downtime-view")
                              ? "link-primary cursor_pointer"
                              : "disabled",
                          ].join(" ")}
                          onClick={() => {
                            if (
                              this.props.permissions.includes("downtime-view")
                            ) {
                              this.viewDowntimeDetail(data.transaction_id);
                            }
                          }}
                        >
                          {data.transaction_id ? data.transaction_id : "-"}
                        </td>
                        <td>
                          {data.asset && data.asset.name
                            ? data.asset.name
                            : "-"}
                        </td>
                        <td>
                          {data.asset && data.asset.asset_type_name
                            ? data.asset.asset_type_name
                            : "-"}
                        </td>
                        <td>
                          {data.downtime_type ? data.downtime_type.name : "-"}
                        </td>
                        <td className="text-center">
                          {data.downtime_seconds
                            ? this.SecondsToHHMM(data.downtime_seconds)
                            : "0"}
                        </td>
                        <td>
                          {data.meter_reading &&
                          data.meter_reading.length > 0 ? (
                            <span className="text-start">
                              {data.meter_reading
                                .filter((s) =>
                                  s.label !== "Date" ? true : false
                                )
                                .map((item, index) => {
                                  return (
                                    <div className="mt-1">
                                      {item.label +
                                        " : " +
                                        item.reading +
                                        " " +
                                        item.unit}
                                    </div>
                                  );
                                })}
                            </span>
                          ) : (
                            "No Record"
                          )}
                        </td>

                        <td className="text-center">
                          <button
                            type="button"
                            id="actionMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="btn"
                          >
                            <tapIcon.FontAwesomeIcon
                              icon={tapIcon.faEllipsisV}
                            />
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <a
                              role="button"
                              onClick={() => {
                                if (
                                  this.props.permissions.includes(
                                    "downtime-view"
                                  )
                                ) {
                                  return this.viewDowntimeDetail(
                                    data.transaction_id
                                  );
                                }
                              }}
                              className={[
                                "dropdown-item",
                                this.props.permissions.includes("downtime-view")
                                  ? ""
                                  : "disabled",
                              ].join(" ")}
                              title={
                                !this.props.permissions.includes(
                                  "downtime-view"
                                )
                                  ? "You do not have permission to perform this action"
                                  : ""
                              }
                              style={{ pointerEvents: "all" }}
                            >
                              View
                            </a>
                          </ul>
                        </td>
                      </Ax>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <DisplayListPagination
          meta={this.state.listingMeta}
          onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
        />
      </Ax>
    );
  };

  downtimeSearchModalJsx = () => {
    return (
      <div className="modal fade" id="downtimeSearchModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="downtimeSearchModalLabel">
                Downtime Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={this.downtimeSearchFormSubmit}
              id="downtimeSearchForm"
            >
              <div className="modal-body">
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Transaction ID
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      name="search_transaction_id"
                      className="form-control form-control-sm"
                      placeholder="Transaction ID"
                      autoComplete="off"
                      value={
                        this.state.downtimeSearchForm.search_transaction_id
                      }
                      onChange={(e) =>
                        this.formInputHandler(e, "downtimeSearchForm")
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset
                  </label>
                  <div className="col-sm-10">
                    <InputAssetSearch
                      containerHeight="30px"
                      fontSize="93%"
                      changeEvent={(selectedOption) => {
                        this.tapSelectChange(
                          selectedOption,
                          "downtimeSearchForm",
                          "search_asset_ids"
                        );
                        this.setState({
                          searchedAssets:
                            selectedOption && selectedOption.length > 0
                              ? selectedOption
                              : [],
                        });
                      }}
                      isMulti={true}
                      menuPlacement="top"
                      value={
                        this.state.searchedAssets.length > 0
                          ? this.state.searchedAssets.map((a) => {
                              a.label = a.display_label;
                              return a;
                            })
                          : []
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Type Group
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.allAssetTypesGroupsList}
                      changeEvent={(selectedOption) => {
                        this.setState({
                          downtimeSearchForm: {
                            ...this.state.downtimeSearchForm,
                            asset_group_ids:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption.map((o) => o.value)
                                : [],
                            search_asset_type_ids:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption
                                    .map((s) => s.linked_asset_types)
                                    .flat()
                                : [],
                          },
                        });
                      }}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.allAssetTypesGroupsList.filter((s) =>
                        this.state.downtimeSearchForm.asset_group_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Asset Type Group"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Type
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.iam_asset_types}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "downtimeSearchForm",
                          "search_asset_type_ids"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_asset_types.filter((s) =>
                        this.state.downtimeSearchForm.search_asset_type_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Asset Type"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Site Group
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.allSiteGroupsList}
                      changeEvent={(selectedOption) => {
                        this.setState({
                          downtimeSearchForm: {
                            ...this.state.downtimeSearchForm,
                            site_group_ids:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption.map((o) => o.value)
                                : [],
                            search_site_ids:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption
                                    .map((s) => s.linked_sites)
                                    .flat()
                                : [],
                          },
                        });
                      }}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.allSiteGroupsList.filter((s) =>
                        this.state.downtimeSearchForm.site_group_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Site Group"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Executed Site
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.iam_group_sites}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "downtimeSearchForm",
                          "search_site_ids"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_group_sites.filter((s) =>
                        this.state.downtimeSearchForm.search_site_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Executed Site"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Type of Downtime
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.allDowntimeTypes}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "downtimeSearchForm",
                          "search_downtime_types"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      value={this.state.allDowntimeTypes.filter((s) =>
                        this.state.downtimeSearchForm?.search_downtime_types?.includes(
                          s.value
                        )
                      )}
                      placeholder="All"
                      isMulti={true}
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Section
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.allSectionList}
                      changeEvent={(selectedOption) => {
                        this.tapSelectChange(
                          selectedOption,
                          "downtimeSearchForm",
                          "search_section_ids"
                        );
                      }}
                      isSearchable={true}
                      isMulti={true}
                      isClearable={true}
                      value={this.state.allSectionList.filter((s) =>
                        this.state.downtimeSearchForm.search_section_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Section"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>

                <div className="row my-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Defect Codes
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.allDefectCodes}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "downtimeSearchForm",
                          "search_defect_codes"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.allDefectCodes.filter((s) =>
                        this.state.downtimeSearchForm.search_defect_codes.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Defect Codes"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Downtime Duration (Range)
                  </label>
                  <div className="col-sm-5">
                    <DatePicker
                      onChange={(value, event) => {
                        this.setState({
                          downtimeSearchForm: {
                            ...this.state.downtimeSearchForm,
                            search_downtime_from: value,
                          },
                        });
                      }}
                      selected={
                        this.state.downtimeSearchForm?.search_downtime_from
                          ? this.state.downtimeSearchForm.search_downtime_from
                          : ""
                      }
                      className="form-control form-control-sm"
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      timeCaption="Hours"
                      dateFormat="HH:mm"
                      autoComplete={"off"}
                      placeholderText={`Select Downtime From`}
                    />
                  </div>
                  <div className="col-sm-5">
                    <DatePicker
                      onChange={(value, event) => {
                        this.setState({
                          downtimeSearchForm: {
                            ...this.state.downtimeSearchForm,
                            search_downtime_to: value,
                          },
                        });
                      }}
                      selected={
                        this.state.downtimeSearchForm?.search_downtime_to
                          ? this.state.downtimeSearchForm.search_downtime_to
                          : ""
                      }
                      className="form-control form-control-sm"
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      timeCaption="Hours"
                      dateFormat="HH:mm"
                      autoComplete={"off"}
                      placeholderText={`Select Downtime To`}
                    />
                  </div>
                </div>

                <div className="row my-2 align-items-center">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end ">
                    Transaction Period
                  </label>

                  <div className="col-sm-10">
                    <PeriodSelectionWithCustom
                      periods={
                        this.state.all_periods ? this.state.all_periods : []
                      }
                      value={this.state.downtimeSearchForm.date_range}
                      startDate={this.state.downtimeSearchForm.date_range_start}
                      endDate={this.state.downtimeSearchForm.date_range_end}
                      onSelectPeriod={(period, startDate, endDate) => {
                        this.setState({
                          downtimeSearchForm: {
                            ...this.state.downtimeSearchForm,
                            date_range: period,
                            date_range_start: startDate ? startDate : null,
                            date_range_end: endDate ? endDate : null,
                          },
                        });
                      }}
                      className="form-control-sm"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  downtimeViewJsx = () => {
    let { downtimeViewData } = this.state;
    return (
      <Ax>
        <div className="row">
          <div className="col-sm-9">
            <DowntimeView downtimeViewData={downtimeViewData} />
          </div>
          <div className="col-sm-3">
            {downtimeViewData && downtimeViewData.asset ? (
              <AssetCard assetId={downtimeViewData.asset.asset_id} />
            ) : null}
          </div>
        </div>
      </Ax>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Downtime Listing</title>
        </Helmet>
        {this.downtimeListjsx()}
        {this.downtimeSearchModalJsx()}
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    all_periods:
      state.app && state.app.all_periods ? state.app.all_periods : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    iam_group_sites:
      state.app && state.app.group_sites ? state.app.group_sites : [],
    asset_groups:
      state.app && state.app.asset_groups ? state.app.asset_groups : [],
    site_groups:
      state.app && state.app.site_groups ? state.app.site_groups : [],
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps)(DowntimeList);
