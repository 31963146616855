import moment from 'moment';

const DateService         =   {

    addDays                 :   function(days = 1, date = null) {
        var result             =   date ? new Date(date) : new Date();
        result.setDate(result.getDate() + days);
        return result;
    },

    subDays                 :   function(days = 1, date = null) {
        var result             =   date ? new Date(date) : new Date();
        result.setDate(result.getDate() - days);
        return result;
    },

    isValidDate             :   function(date) {
        return new Date(date).toString() == 'Invalid Date' ? false : true;
    },

    daysToMilliseconds      :   function(days) {
        return days * 24 * 60 * 60 * 1000;
    },

    differenceInDays        :   function(date1, date2=null) {
        if (date1 && this.isValidDate(date1)) {
            var date1Obj                =   new Date(date1);
            var date2Obj                =   date2 && this.isValidDate(date2) ? new Date(date2) : new Date();
            let difference_in_time      =   date2Obj.getTime() - date1Obj.getTime();
            return   Math.round(difference_in_time / (1000 * 3600 * 24));
        }
        return null;
    },



    convertSeconds      :       function(seconds, separator = ":") {
        const hours             =   Math.floor(seconds / 3600);
        const remainingSeconds  =   seconds % 3600;
        const minutes           =   Math.floor(remainingSeconds / 60);
        const secs              =   remainingSeconds % 60;

        if (hours > 0) {
            return `${hours}${separator}${minutes < 10 ? '0' : ''}${minutes}`;
        } else {
            return `0${separator}${minutes}${separator}${secs < 10 ? '0' : ''}${secs}`;
        }
    },

    dateTimeFormat: function (date, format = 'DD-MMM-YYYY HH:mm A') {
        if (date && this.isValidDate(date)) {
            return moment(date).format(format);
        }
        return null;
    },

    dateFormat: function (date, format = 'DD-MMM-YYYY') {
        if (date && this.isValidDate(date)) {
            return moment(date).format(format);
        }
        return null;
    },

    
    
};

export default DateService;