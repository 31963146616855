import React from 'react';



class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
          return (<div className='bg-white d-flex justify-content-center align-items-center' style={{height: "100vh"}}>
              <div><h1>Something Went Wrong</h1><br/>
              <h5>Please go to  <a className="link-primary" href="/dashboard">Dashboard</a></h5></div>
             
          </div>)
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;