import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import DatePicker from "react-datepicker";
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import TapHelper from '../../../services/TapHelper';
import TapIcon from '../../../services/TapIcon';
import CustomerDetailModal from '../../groupData/customer/CustomerDetailModal';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from '../../../components/ui/Loader/Loader';


const CustomerInput = forwardRef((props, ref) => {

    let initPrjectListData                  =   {
        loading                                 :   false,
        items                                   :   [],
        meta                                    :   null,
        page                                    :   1,
        per_page                                :   50
    };
    const [projectsData, setProjectsData]   =   useState({...initPrjectListData});

    let initData                            =   {
        data_loaded                             :   false,
        loading                                 :   false,
        search_customer                         :   null,
        customer                                :   null,
        customer_id                             :   null,
        addressList                             :   [],
        shippingAddress                         :   null,
        shipping_address_id                     :   null,
        billingAddress                          :   null,
        billing_address_id                      :   null,
        billing_state_id                        :   null,
        address_source                          :   'customer',
        customerProject                         :   null,
        customer_project_id                     :   null,
    };
    
    let initAddressFormData                 =   {
        address_source                          :   'customer',
        billing_address_id                      :   null,
        billing_state_id                        :   null,
        shipping_address_id                     :   null,
        customer_project_id                     :   null,

    }
    
    const customerDetailModalRef            =   useRef(null);
    const access_token                      =   useSelector(state => state.auth.access_token);

    const [data, setData]                   =   useState({...initData});
    const [addressFormData, setAddressFormData] =   useState({...initAddressFormData});

    const [addressModalId, setAddressModalId]=   useState(uuid());
    const addressModalElem                  =   useRef(null);
    const addressModal                      =   useRef(null);
    const addressModalOpen                  =   ()  => { if (addressModal.current) { addressModal.current.show(); } }
    const addressModalClose                 =   ()  => { if (addressModal.current) { addressModal.current.hide(); } }

    let customerUrl                         =   INVENTORY_API_BASE_URL_2 + '/customer';
    let customerProjectUrl                  =   INVENTORY_API_BASE_URL_2 + '/customer_project/list';

    useEffect(() => {
        addressModalElem.current            =   document.getElementById(addressModalId);
        addressModal.current                =   new Modal(addressModalElem.current, {keyboard: false, backdrop :false});

        return () => {
            if (addressModal.current) {  addressModal.current.dispose(); }
        };
    }, []);

    useEffect(() => {
        returnToParentData();
    }, [data]);

    useImperativeHandle(ref, () => ({
        updateCustomerDetail: (customer_id) => {
            updateCustomerData(customer_id);
        }
    }));

    const viewCustomerDetailsInit       =   ()  =>  {
        if(data.customer_id && customerDetailModalRef.current) {
            customerDetailModalRef.current.initModalHandler(data.customer_id);
        }
    }

    const addCustomerModalInit          =   async ()  =>  {
        if(props.onClickAddCustomer) {
            await props.onClickAddCustomer();
        }
        if(props.customerAddModalRef.current) {
            props.customerAddModalRef.current.customerAddModalInit();
        }
    }

    const editCustomerModalInit         =   ()  =>  {
        if(data.customer_id && data.customer && props.customerAddModalRef.current) {
            props.customerAddModalRef.current.customerAddModalInit(data.customer);
        }
    }

    const customerProjectsLoadingInit   =   (page=1)  => {
        setProjectsData(pd => ({...pd, loading : true, page : page }));
        let params                      =   {
                                                page                :   page,
                                                per_page            :   100,
                                                search_customer_ids :   [data.customer_id]
                                            };
        HttpAPICall.withAthorization('GET', customerProjectUrl, access_token, params, null, (response) => {
            let respData                    =   response.data;
            setProjectsData(pd => ({...pd, 
                items       :   respData.data, 
                meta        :   TapHelper.pick(respData, ['from', 'to', 'total', 'last_page','per_page','current_page']) 
            }));
        }).then(() => {
            setProjectsData(pd => ({...pd, loading : false}));
        });
    }

    const updateCustomerData            =   (customer_id=null, shipping_address_id = null, billing_address_id=null, project_id=null)  =>  {
        if(customer_id) {
            setData(d => ({...d, loading : true, customer : null}));
            HttpAPICall.withAthorization('GET', customerUrl + '/' + customer_id, access_token, null, null, (response) => {
                let respData                    =   response.data;
                let addressList                 =   respData.data ? respData.data.addressList : [];

                let shippingAddress             =   null;
                if(shipping_address_id) {
                    shippingAddress             =   addressList.find(a => a.id == shipping_address_id)
                } else if(addressList && addressList.length > 0) {
                    shippingAddress             =   addressList[0];
                }
                let billingAddress             =   null;
                if(billing_address_id) {
                    billingAddress             =   addressList.find(a => a.id == billing_address_id)
                } else if(addressList && addressList.length > 0) {
                    billingAddress             =   addressList[0];
                }
                setData(d => ({...d,
                    customer_id         :   customer_id,
                    customer            :   respData.data,
                    search_customer     :   respData.data,
                    addressList         :   addressList,
                    shippingAddress     :   shippingAddress,
                    shipping_address_id :   shippingAddress ? shippingAddress.id : null,
                    billingAddress      :   billingAddress,
                    billing_address_id  :   billingAddress ? billingAddress.id : null,
                    billing_state_id    :   billingAddress ? billingAddress.state_id : null
                }));
            }).then(() => {
                setData(d => ({...d, loading : false}));
            });
        } else {
            setData(d => ({...d, ...initData}))
        }
    }
    
    const returnToParentData                =   ()  =>  {
        if(props.afterChangeData) {
            setTimeout(() => {
                let returnData              =   TapHelper.pick(data, ['customer', 'customer_id','shipping_address_id','billing_address_id','address_source', 'customer_project_id', 'billing_state_id']);
                props.afterChangeData(returnData);
            }, 1000);
        }
    }

    const viewCustomerAddressInit       =   ()  =>  {
        if(data.customer_id && data.customer) {
            setAddressFormData(d => ({...d,
                address_source          :   data.address_source,
                billing_address_id      :   data.billing_address_id,
                shipping_address_id     :   data.shipping_address_id,
            }));
            addressModalOpen();
        }
    }

    const customerAddressSubmit         =   () => {
        let add_data                    =   {...initAddressFormData, address_source : addressFormData.address_source};
        let customerProjectAddress      =   null;
        let customerProject             =   null;
        if(addressFormData.address_source == 'project') {
            add_data                    =   {...add_data, customer_project_id :  addressFormData.customer_project_id};
            customerProject             =   projectsData.items.find(p => p.id == addressFormData.customer_project_id);
            customerProjectAddress      =   customerProject ? customerProject.address : null;
        } else {
            add_data                    =   {...add_data, billing_address_id :  addressFormData.billing_address_id, shipping_address_id : addressFormData.shipping_address_id};
        }
        if(add_data.address_source == 'project' && (add_data.customer_project_id == '' || add_data.customer_project_id == null)) {
            toast.error("Please select Project.", {position: toast.POSITION.TOP_RIGHT});
        } else if(add_data.address_source == 'customer' && (add_data.shipping_address_id == null || add_data.billing_address_id == null)) {
            toast.error("Please select Project.", {position: toast.POSITION.TOP_RIGHT});
        } else {

            let billingAddress          =   add_data.billing_address_id 
                                                ? data.addressList.find(a => a.id == addressFormData.billing_address_id) 
                                                : customerProjectAddress;
            setData(d => ({...d,
                address_source          :   add_data.address_source,
                billing_address_id      :   add_data.billing_address_id,
                shipping_address_id     :   add_data.shipping_address_id,
                billingAddress          :   billingAddress,
                shippingAddress         :   add_data.shipping_address_id 
                                                ? data.addressList.find(a => a.id == addressFormData.shipping_address_id) 
                                                : customerProjectAddress,
                customer_project_id     :   add_data.customer_project_id,
                customerProject         :   customerProject,
                billing_state_id        :   billingAddress ? billingAddress.state_id : null
            }));
            addressModalClose();
        }
    }

    const addessModalJsx                =   ()  =>  {
        return <div className="modal fade" id={addressModalId} tabIndex="-1" ref={addressModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Address</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label className="form-label">Choose Address Source : </label>
                            </div>
                            <div className="col-sm-9">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" name="address_source" type="radio" id="addproject" 
                                        value="project"
                                        checked={addressFormData.address_source === 'project'}
                                        onChange={() => {
                                            setAddressFormData(d => ({...d, address_source : 'project'}));
                                            customerProjectsLoadingInit(1);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="addproject">Project</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" name="address_source" type="radio" id="addcustomer"
                                        value="customer"
                                        checked={addressFormData.address_source === 'customer'}
                                        onChange={() => {setAddressFormData(d => ({...d, address_source : 'customer'}));}}
                                    />
                                    <label className="form-check-label" htmlFor="addcustomer">Customer</label>
                                </div>
                            </div>
                        </div>
                        {addressFormData.address_source == 'project' && <>
                            <table className="table table-bordered table-fixed bg-white table-sm">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th style={{ width: "5%" }}>Select</th>
                                        <th style={{ width: "20%" }}>Project Name</th>
                                        <th style={{ width: "20%" }}>Project Code</th>
                                        <th style={{ width: "55%" }}>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectsData.loading 
                                        ? <tr><td className='text-center' colSpan={4}><Loader /></td></tr>
                                        : ((projectsData.items && projectsData.items.length > 0) 
                                            ? projectsData.items.map((item,index) => {
                                                return <tr className="" key={index}>
                                                    <td className='text-center'>
                                                        <input className="form-check-input" 
                                                            type="checkbox"
                                                            value="customer"
                                                            checked={addressFormData.customer_project_id === item.id}
                                                            onChange={() => {setAddressFormData(d => ({...d, customer_project_id : item.id}));}}
                                                        />
                                                    </td>
                                                    <td>{item.project_name}</td>
                                                    <td>{item.project_code}</td>
                                                    <td>{item.address ? formatAddress(item.address) : ''}</td>
                                                </tr>
                                              })
                                            : <tr><td className='text-center' colSpan={4}>No Record</td></tr>  )
                                    }
                                </tbody>
                            </table>
                        </>}
                        {addressFormData.address_source == 'customer' && <>
                            <table className="table table-hover table-bordered">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{width:"60%"}}>Address</th>
                                        <th style={{width:"20%"}} className="text-center">Shipping Address</th>
                                        <th style={{width:"20%"}} className="text-center">Billing Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.addressList.map((address, index) => {
                                    return <tr key={index}>
                                        <td>{formatAddress(address)}</td>
                                        <td className='text-center align-middle'>
                                            <input className="form-check-input" 
                                                type="checkbox"
                                                value="customer"
                                                checked={addressFormData.shipping_address_id === address.id}
                                                onChange={() => {setAddressFormData(d => ({...d, shipping_address_id : address.id}));}}
                                            />
                                        </td>
                                        <td className='text-center align-middle'>
                                            <input className="form-check-input" 
                                                type="checkbox"
                                                value="customer"
                                                checked={addressFormData.billing_address_id === address.id}
                                                onChange={() => {setAddressFormData(d => ({...d, billing_address_id : address.id}));}}
                                            />
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={customerAddressSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    }

    const formatAddress                 =   (address) => {
        return <>
            {address.address1 ? address.address1 + ", " : ""}  {address.address2 ? address.address2 + ", " : ""}
            {address.landmark ? address.landmark + ", " : ""} {address.city ? address.city + ". " : ""}<br />
            Location : <b>{address.state_name ? address.state_name + ", " : ""}  {address.district_name ? address.district_name + "& " : ""}  {address.pincode ? address.pincode + "." : ""} <br /></b>
            GSTIN   :   <b>{address.gstin ? address.gstin : "-"}</b>
        </>
    }

    return <>
        <div className="row align-items-center">
            <div className="col-sm-6">
                <InputCustomerSearch
                    changeEvent={(so) => {
                        setData(d => ({...d,
                            customer_id     :   so ? so.value : null,
                            search_customer :   so ? so.customer : null,
                        }));
                        updateCustomerData(so ? so.value : null);
                    }}
                    value={data.search_customer ? {...data.search_customer, label : data.search_customer.name} : null }
                    only_active={props.only_active || 'Y'}
                    disabled={props.disabled}
                />
            </div>
            <div className="col-sm-6">
                {data.customer && data.customer_id && <>
                    <button type="button" className='btn btn-primary' style={{ height: "38px" }}  onClick={viewCustomerDetailsInit}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEye} className='' />
                    </button>
                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary ms-1" style={{ height: "38px" }}><TapIcon.imageIcon icon={TapIcon.ActionIcon} className="" alt="item-action" /></button>
                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer" aria-labelledby="dropdownMenuButton1">
                        <li><Link className="dropdown-item" onClick={viewCustomerAddressInit}>Change Address</Link></li>
                        <li><Link className="dropdown-item" onClick={editCustomerModalInit}>Edit Customer</Link></li>
                    </ul>
                </>}
                {props.add_customer_btn && <button type="button" onClick={addCustomerModalInit} className="btn btn-link text-decoration-none ms-1"><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Add Customer</button>}
            </div>
        </div>
        {data.loading && <div className="col-sm-12 my-2"><Loader /></div>}
        {data.customer && data.customer_id && !data.loading && <><div className="row align-items-center my-3">
            <div className="col-sm-6">
                <div className='my-2 fw-bold'>Shipping Address</div>
                <label className="form-label">
                    {data.address_source == 'project' && data.customerProject && <div><span className='fw-bold'>Project :</span>{data.customerProject.project_name}</div>}
                    Address: {data.shippingAddress ? formatAddress(data.shippingAddress) : "-"}
                </label>
            </div>
            <div className="col-sm-6">
                <div className='my-2 fw-bold'>Billing Address</div>
                <label className="form-label">
                    {data.address_source == 'project' && data.customerProject && <div><span className='fw-bold'>Project :</span>{data.customerProject.project_name}</div>}
                    Address: {data.billingAddress ? formatAddress(data.billingAddress) : "-"}
                </label>
            </div>
        </div></>}
        <CustomerDetailModal ref={customerDetailModalRef} />
        {addessModalJsx()}
    </>
});

export default CustomerInput;