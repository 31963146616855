import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import download from '../../../services/DownloadFile';
import moment from 'moment';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import ConsumptionDetailsModal from '../../inventory/consumption/ConsumptionDetailsModal';

class AssetConsumption extends AppBaseComponent {
    
    constructor(props) {
        super(props);
    
        this.initConsumptionSearchForm         =       {
            search_transaction_id           :       '',
            date_range                      :       null,
            date_range_start                :       null,
            date_range_end                  :       null,
            search_warehouse_ids            :       [],
            search_workstation_ids          :       [],
            search_voucher_number           :       '',
            search_item_ids                 :       [],
            search_employee_ids             :       [],
        }

        this.consumptionSearchFormLabel     =       {
            search_transaction_id           :       'Transaction ID : ',
            date_range                      :       'Date Range : ',
            search_warehouse_ids            :       'Warehouses : ',
            search_workstation_ids          :       'Workstations : ',
            search_voucher_number           :       'Voucher Number : ',
            search_item_ids                 :       'Items : ',
            search_employee_ids                 :   'Employees : ',
        }

        this.updateCommentFormInit            =        {
            status                            :         '',
            notes                             :         '',
        }

        this.state                           =     {
            consumptionAddScreenView          :        false,
            consumptionUpdateScreenView       :       false,
            minimizeTable                     :       false,
            consumptionFormDataLoaded         :       false,
            consumptionAddFormData            :       null,
            listing_loading                   :       false,
            listing_tbl_page                  :       1,
            listingData               :       [],
            listingMeta                       :       null,
            totalListingCount                 :       0,
            view_loading                      :       false,
            consumptionViewData               :       null,
            consumptionTransactionId            :   '',
            isc_configuration                 :       {},
            pdfDownloading                    :       false,
            reportDownloading                 :      false,
            searchedAssets                    :        [],
            searchedItem                      :        [],
            consumptionSearchForm             :       {...this.initConsumptionSearchForm},
            submittedConsumptionFilterForm    :       {...this.initConsumptionSearchForm},
            formSearchedElems                 :        [],
            allWorkstations                   :        [],
            all_periods                        :        [],
            consumptionSearchFormDataLoaded   :         false,
            openingUpdateScreen               :         false,
            searchedEmployees                   :   [],
            addCommentForm                  :       {...this.updateCommentFormInit},
            allAllowedStatus                :       [],
            addCommentFormSubmitting        :       false,

        };
        //this.myCommnetRef                         =   React.createRef();
        this.CONSUMPTION_URL                    =   INVENTORY_API_BASE_URL_2 + '/consumption';
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.consumptionSearchModal                         =   new Modal(document.getElementById('consumptionSearchModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        this.setState({
                assetId                 :       pr.match?.params?.id,
                all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                all_periods             :        all_periods ,
                isc_configuration       :        pr.isc_configuration
            },() => {
                    
            this.getAsssetDetails(pr.match?.params?.id)
            this.loadListingTblData(1,pr.match?.params?.id)
            // this.searchFormDataInit(pr)
        })
    }
    

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    loadListingTblData(page,id){
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedConsumptionFilterForm,search_asset_ids : [id ? id : this.state.assetId],for_report:'N'};
            HttpAPICall.withAthorization('GET',  INVENTORY_API_BASE_URL_2 + '/report/item-wise-consumption', this.props.access_token, params, {}, (resp) => {
                let respData            =       resp.data;
                this.setState({
                    listingData            :       respData.data,
                    listingMeta                 :   {
                        from                        :   respData.from,
                        to                          :   respData.to,
                        total                       :   respData.total,
                        last_page                   :   respData.last_page,
                        per_page                    :   respData.per_page,
                        current_page                :   respData.current_page
                    },
                    totalListingCount      :       respData.to,
            });
        }).then(() => this.setState({listing_loading: false}));
    }

    consumptionSearchModalInit               =       ()             =>        {
        this.consumptionSearchModal.show();
        let all_periods                 =   [...this.props.all_periods , {key : 'custom', display : 'Custom Date Range'}];
        if(!this.state.consumptionSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.CONSUMPTION_URL + '/add_form_data', this.props.access_token, null, null, (response) => {
                this.setState({
                    consumptionSearchFormDataLoaded    :    true,
                    allWorkstations                    :    response.data.workstations.map((s) => { return {value: s.id, label: s.workstation_name}})
                });
            });
        }
    }

    submitConsumptionSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.consumptionSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.consumptionSearchFormLabel[key];
            let value                       =   this.state.consumptionSearchForm[key];
           
            if(value && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
                    let show_val            =   value;
                    if(key == 'search_workstation_ids') {
                        show_val            =  this.state.allWorkstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.consumptionSearchForm ? this.state.consumptionSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_voucher_number') {
                        show_val            =   this.state.consumptionSearchForm ? this.state.consumptionSearchForm.search_voucher_number : '';
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.consumptionSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.consumptionSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedConsumptionFilterForm   :   {...this.state.consumptionSearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.consumptionSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    consumptionSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedEmployees               :   [],
            searchedItem                    :   [],
            consumptionSearchForm            :   {...this.initConsumptionSearchForm},
            submittedConsumptionFilterForm     :   {...this.initConsumptionSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.consumptionSearchModal.hide();
        });
    }

    viewConsumptionDetail          =      (id)        =>     {
        this.setState({minimizeTable  :   true})
        this.getConsumptionViewDetails(id)
       
    }

    
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    getConsumptionViewDetails             =    (transaction_id)        =>  {
        // if(transaction_id !== this.state.consumptionTransactionId){
         this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET',  this.CONSUMPTION_URL + '/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 consumptionViewData        :   respData.data,
                 consumptionTransactionId   :   respData.data.transaction_id,
                 allAllowedStatus           :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                 addCommentForm             :   {...this.updateCommentFormInit , status : respData.data.status}
             });
            
         },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
        // }
     }

     deleteConsumption              =           (transaction_id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.CONSUMPTION_URL + '/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     }).then(() => {

                    });
                }
            });
    }

      //***********************DOWNLOAD EXCEL **************************** */
      consumptionDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.submittedConsumptionFilterForm,search_asset_ids : [this.state.assetId]};
         if(JSON.stringify(this.state.submittedConsumptionFilterForm) !== JSON.stringify(this.initConsumptionSearchForm)){
             this.reportDownloadHandler(params);
         } else {
             swal({ title: "Download",
             text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
             icon: "warning",
             buttons: ["Cancel", "Okay"],
             }).then(willDownload => {
                 if (willDownload) {
                     params = {...params , date_range: "current_month"}
                     this.reportDownloadHandler(params);
                 }
             });
         }
     }

     reportDownloadHandler                   =   (params)  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/report/item-wise-consumption', this.props.access_token, {...params, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

     updateComment           =           ()          =>      {
        // this.myCommnetRef.current.scrollIntoView()
    }

     submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.consumptionTransactionId
           }
           HttpAPICall.withAthorization('PUT', this.CONSUMPTION_URL + '/comment_add', this.props.access_token, {}, { ...frmData }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.getConsumptionViewDetails(this.state.consumptionTransactionId);
               this.loadListingTblData(1)
               this.setState({addCommentForm : {...this.updateCommentFormInit}})
           }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }



    closeConsumptionView                    =      ()              =>         {
        this.setState({minimizeTable:false})
    }

   
   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader  assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div> 
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>
        </div>)
    }

    consumptionListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Consumption List</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                            <button type="button" disabled={this.state.listing_loading} onClick={this.consumptionSearchModalInit} className="btn btn-secondary" >
                                <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <button type="button" className="btn btn-secondary"
                                disabled={this.state.reportDownloading || this.state.listing_loading}
                                onClick={this.consumptionDownloadHandler} >
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                                {this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div> : null}
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                        <h3 className="text-capitalize" style={{paddingTop:"12px"}}>Consumption : {this.state.consumptionTransactionId}</h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>
                                    <button  disabled={this.state.consumptionViewData?.transaction_type == "purchase_consumption" ? true : false || this.state.view_loading} title={this.state.consumptionViewData?.transaction_type == "purchase_consumption" ? 'This consumption is logged along with purchase. Hence it can only be edited from purchase voucher.' : ''} style={{ "pointer-events": "all" }}
                                type="button" className="btn btn-secondary"  onClick={() => this.updateComment()} >Update Status </button>
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                           
                                            <li>
                                                <a role="button" href
                                                    onClick={() => {
                                                        if (this.state.consumptionViewData?.transaction_type !== "purchase_consumption") {
                                                            return this.deleteConsumption(this.state.consumptionTransactionId);
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.state.consumptionViewData?.transaction_type !== "purchase_consumption" ? '' : 'disabled'].join(' ')}
                                                    title={this.state.consumptionViewData?.transaction_type == "purchase_consumption" ? 'This consumption is logged along with purchase. Hence it can only be edited from purchase voucher.' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete</a>
                                            </li>

                                        </ul>
                                        <button onClick={this.closeConsumptionView} type="button" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.consumptionTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0 pageTbl pb-5"><div className=" bg-white p-2" >{this.state.view_loading ? <Loader /> : this.consumptionViewDetailJsx()}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }

    consumptionTableJsx                   =   ()  =>  {
        return (
            <Ax>
                 <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.consumptionSearchClear} />  
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0">
                        <thead className="table-secondary">
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "20%" }}>Consumption</th>
                                </tr>)
                                : (<tr>
                                    <th className="text-center" style={{ width: "20%" }}>Item Name</th>
                                    <th className="text-center" style={{ width: "20%" }}>Item Code</th>
                                    <th className='text-start' style={{ width: "10%" }}>Transaction ID</th>
                                    <th className='text-start' style={{ width: "10%" }}>Transaction Date</th>
                                    <th className="text-center" style={{ width: "13%" }}>Warehouse</th>
                                    <th scope="col" style={{ width: "8%" }} > Status</th>
                                    <th scope="col" style={{ width: "5%" }} > Qty</th>
                                    <th scope="col" style={{ width: "8%" }} > Amount</th>
                                    
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }

                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                : (this.state.listingData.length > 0
                                    ? (this.state.listingData.map((rowData, index) => {
                                        return (<tr key={index} >
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }}>
                                                                <a href="#" className="text-capitalize link-primary" onClick={() => this.viewConsumptionDetail(rowData.transaction_id)}>{rowData.transaction_id ? <div>{rowData.transaction_id} ({rowData.transaction_date_display}) </div> : "-"}  </a>
                                                                <div className="mt-1">
                                                                    <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{rowData.warehouse_name}</span> </small>
                                                                    <small className="float-end">{rowData.status && rowData.status.name ? rowData.status.name : "-"}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td>{rowData.item_name}</td>
                                                        <td>{rowData.item_code}</td>
                                                        <td><a role="button" className="link-primary" onClick={() => this.viewConsumptionDetail(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                                        <td>{rowData.transaction_date_display}</td>
                                                        <td>{rowData.warehouse_name}</td>
                                                       
                                                        <td>{rowData.status && rowData.status.name ? rowData.status.name : "-"}</td>
                                                        <td className="text-center">{rowData.qty}</td>
                                                        <td className="text-end">{rowData.amount}</td>
                                                        {/* <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td className='text-center'>{item.transaction_date_display}</td>
                                                        <td><a role="button" className="link-primary" onClick={() => this.viewConsumptionDetail(item.transaction_id)}>{item.transaction_id ? item.transaction_id : "-"}</a></td>
                                                        <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                            : "-"}</td>
                                                       <td className='text-center'>{item.total_qty ? item.total_qty : 0}</td>
                                                        <td className='text-end'>{item.total_amount ? (item.total_amount).toFixed(2) : 0}</td>
                                                        <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td> */}
                                                        <td className="text-center">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewConsumptionDetail(rowData.transaction_id)}>View</a></li>
                                                            </ul>
                                                        </td>
                                                    </Ax>
                                                    )}
                                        </tr>)
                                    }))
                                    : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                )
                            }
                        </tbody>

                    </table>

                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                    />
                </div>

            </Ax>
        );
    }

    consumptionViewDetailJsx                =   ()   =>   {
        let consumption = this.state.consumptionViewData;
        return (<div>
            <div className="row mr0">
                <div className="col-sm-12 pr0 pb-4">
                    <ConsumptionDetailsModal consumptionData={this.state.consumptionViewData} />
                    <hr />
                    {consumption && consumption.transaction_type !== 'purchase_consumption'
                        ? <Ax>
                            <div className='mb-3'>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Comments & History</span>
                                </div>
                                <form onSubmit={this.submitUpdateCommentForm}>
                                    <div className="my-3 row">
                                        <label className="col-sm-3 col-form-label">Change status to</label>
                                        <div className="col-sm-5">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                                }}
                                                value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                options={this.state.allAllowedStatus}
                                                isSearchable={false}
                                                // isDisabled={this.state.poDetail?.change_status == "N"}
                                                placeholder="Please Select Status"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <label className="form-label">Comments</label>
                                        <textarea
                                            name="notes"
                                            value={this.state.addCommentForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                            className="form-control"
                                            placeholder="Please Add a comment"
                                            required
                                            style={{ height: "100px" }}
                                        />
                                    </div>
                                    <div className="col-12 text-end">
                                        <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                            Update {this.state.addCommentFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>
                                    </div>
                                </form>
                                <hr />
                                <div className="my-1">
                                    {
                                        consumption.comments && consumption.comments.length > 0 ? consumption.comments.map((c, k) => {
                                            return (<Ax key={k}><div className="row">
                                                {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                                <div className="col-sm-4 text-end">
                                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                    <span> {c.created_at_display}</span>
                                                </div>
                                                <div className="col-sm-8 my-1">{c.notes}</div>
                                            </div><hr /></Ax>);
                                        })
                                            : null
                                    }
                                </div>

                            </div>
                        </Ax>
                        : null}
                </div>

            </div>

        </div>)
    }

    consumptionSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="consumptionSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Consumption Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="consumptionSearchForm" onSubmit={this.submitConsumptionSearchForm}>
                           {!this.state.consumptionSearchFormDataLoaded ? <Loader/>
                            :  <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_transaction_id"
                                            type="text"
                                            value={this.state.consumptionSearchForm.search_transaction_id}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "consumptionSearchForm")}
                                            placeholder="Please enter Transaction ID"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Number</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_voucher_number"
                                            type="text"
                                            value={this.state.consumptionSearchForm.search_voucher_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "consumptionSearchForm")}
                                            placeholder="Please enter Voucher Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.all_warehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'consumptionSearchForm', 'search_warehouse_ids')}
                                            value={this.state.all_warehouses.filter(u => this.state.consumptionSearchForm?.search_warehouse_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                             
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                        <div className="col-sm-10">
                                            <InputItemSearch
                                                placeholder="Search Item"
                                                isMulti={true}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'consumptionSearchForm', 'search_item_ids');
                                                    this.setState({
                                                        searchedItem: selectedOption ? selectedOption : null
                                                    }, () => { });
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.consumptionSearchForm.date_range}
                                            startDate={this.state.consumptionSearchForm.date_range_start}
                                            endDate={this.state.consumptionSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({consumptionSearchForm
                                                    : {
                                                        ...this.state.consumptionSearchForm, 
                                                        date_range: period, 
                                                        date_range_start: startDate ? startDate : null, 
                                                        date_range_end: endDate ? endDate : null
                                                      }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={this.consumptionClear}>Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

   
    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Consumption List</title></Helmet>
             {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.consumptionListJsx()}
            {this.consumptionSearchModalJsx()}
            {/* {this.fuelFeedInSearchModalJsx()} */}
        
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetConsumption);