import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Collapse } from "bootstrap";
import { Modal } from "bootstrap";
import HttpAPICall from "../../../services/HttpAPICall";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Loader from "../../../components/ui/Loader/Loader";
import TapIcon from "../../../services/TapIcon";
import moment from "moment";
import Status from "../../../components/ui/Status";
import EmployeeDetailHorizontalCard from "../employee/EmployeeDetailHorizontalCard";
import AttendenceUploadModal from "./AttendenceUploadModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MonthlyAttendence = (props) => {
  const access_token = useSelector((state) => state.auth.access_token);
  const bulkAttendenceModalRef = useRef();
  const selectDateRangeModal = useRef();
  const [monthlyAttendence, setMonthlyAttendence] = useState({
    loading: false,
    employeeLoading: false,
    employeeId: props.employee_id,
    currentPage: 1,
    data: [],
    employeeCardData: [],
    listingMeta: null,
    startDate: null,
    endDate: null,
    selectDateRange: { from: "", to: "" },
  });

  useEffect(() => {
    initializeData();
    selectDateRangeModal.current = new Modal(
      document.getElementById("selectDateRangeModal"),
      { keyboard: false, backdrop: false }
    );
  }, [props.employee_id]);

  const initializeData = () => {
    const employee_id = props.employee_id;
    const page = monthlyAttendence.currentPage;
    if (employee_id) {
      setMonthlyAttendence((prevState) => ({
        ...prevState,
        employeeId: employee_id,
      }));
      loadListingTblData(page, employee_id);
    }
  };
  const handleAttendenceBulk = () => {
    bulkAttendenceModalRef.current.modalHandle();
  };

  const paginationHandler = (type) => {
    const newPageNumber =
      type === "prev"
        ? monthlyAttendence.listingMeta.current_page - 1
        : monthlyAttendence.listingMeta.current_page + 1;
    loadListingTblData(newPageNumber, props.employee_id);
  };

  const refreshLogsheet = () => {
    const { currentPage, startDate, endDate, employeeId } = monthlyAttendence;
    loadListingTblData(currentPage, employeeId, startDate, endDate);
  };

  const loadListingTblData = (
    page = null,
    employee_id,
    date_range_start,
    date_range_end
  ) => {
    setMonthlyAttendence((prevState) => ({ ...prevState, loading: true }));
    const params = {
      page: page,
      date_range_start: page ? null : date_range_start,
      date_range_end: page ? null : date_range_end,
    };
    HttpAPICall.withAthorization("GET", `${HRM_API_BASE_URL_2}/monthly_attendance/employee/${employee_id}`, access_token, params, {}, (resp) => {
        const respData = resp.data;
        setMonthlyAttendence((pd) => ({
          ...pd,
          loading: false,
          data: respData.data,
          employeeCardData: respData.employee,
          listingMeta: respData.meta,
        }));
      }
    ).then(() =>
      setMonthlyAttendence((prevState) => ({ ...prevState, loading: false }))
    );
  };

  const employeeCardHandler = () => {
    new Collapse(document.getElementById("employeeCard"));
    if (document.getElementById("pageTbl")) { document.getElementById("pageTbl").scroll({ top: 0, behavior: "smooth" });}
  };

  const selectDateRangeModalInit = () => {
    selectDateRangeModal.current.show();
    setMonthlyAttendence((prevState) => ({
      ...prevState,
      selectDateRange: { from: "", to: "" },
    }));
  };

  const tapDateChange = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      setMonthlyAttendence((prevState) => ({
        ...prevState,
        selectDateRange: {
          from: selectedOption[0] || "",
          to: selectedOption[1] || "",
        },
      }));
    }
  };

  const submitSearchDateRangeHandler = (e) => {
    e.preventDefault();
    const start_date = moment(
      monthlyAttendence.selectDateRange.from,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
    const end_date = moment(
      monthlyAttendence.selectDateRange.to,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
    setMonthlyAttendence((prevState) => ({
      ...prevState,
      startDate: start_date,
      endDate: end_date,
    }));
    loadListingTblData(
      null,
      monthlyAttendence.employeeId,
      start_date,
      end_date
    );
    selectDateRangeModal.current.hide();
  };

  const closeLogsheet = () => {
    props.showmonthlyattendence();
  };

  const attendanceSheetSubHeadingJsx = () => {
    const { listingMeta, loading } = monthlyAttendence;
    const { from, to, current_page, last_page } = listingMeta || {};

    return (
      <div className="mt-1">
        <div className="bg-white row m0 text-decoration-none d-flex align-items-center justify-content-between">
          <div className="col-sm-5 py-2">
            <h5 className="fs-6 m-0">
              Monthly Attendance :{" "}
              {listingMeta ? (
                <span>
                  {from} - {to}
                </span>
              ) : null}
            </h5>
          </div>
          <div className="col-sm-2 text-center align-self-start">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={employeeCardHandler}
            >
              {" "}
              Employee Card
            </button>
          </div>
          <div className="col-sm-5 text-end my-2">
            <button
              type="button"
              className="btn btn-secondary ms-1"
              disabled={loading || (listingMeta && current_page === last_page)}
              onClick={() => paginationHandler("next")}
            >
              <TapIcon.FontAwesomeIcon
                icon={TapIcon.faArrowLeft}
                className="mx-1"
              />
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-1"
              onClick={selectDateRangeModalInit}
              disabled={loading}
            >
              <TapIcon.FontAwesomeIcon
                icon={TapIcon.faCalendarAlt}
                className="mx-2 cursor_pointer"
                color="white"
              />
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-1"
              disabled={loading || (listingMeta && current_page === 1)}
              onClick={() => paginationHandler("prev")}
            >
              <TapIcon.FontAwesomeIcon
                icon={TapIcon.faArrowRight}
                className="mx-1"
              />
            </button>
            <span className="dropdown ms-1">
              <button
                type="button"
                id="actionMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn btn-secondary"
                disabled={loading}
              >
                <TapIcon.imageIcon
                  icon={TapIcon.ActionIcon}
                  className="img-fluid"
                  alt="item-action"
                />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li onClick={handleAttendenceBulk}>
                  <Link className="dropdown-item" role="button">
                    Upload
                  </Link>
                </li>
              </ul>
            </span>

            <button
              type="button"
              className="btn btn-secondary ms-1"
              onClick={refreshLogsheet}
              disabled={loading}
            >
              <TapIcon.FontAwesomeIcon
                icon={TapIcon.faSyncAlt}
                className={[
                  "cursor_pointer",
                  "img-fluid",
                  loading ? "fa-spin" : "",
                ].join(" ")}
                color="white"
              />
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-1"
              onClick={closeLogsheet}
              disabled={loading}
            >
              <TapIcon.imageIcon
                icon={TapIcon.CloseIcon}
                className="cursor_pointer img-fluid"
                color="white"
              />
            </button>
          </div>
        </div>
      </div>
    );
  };
//   4.2.2.3 - Attendance Upload By Excel
  const employeeAttendenceListJsx = () => {
    const { loading, data } = monthlyAttendence;
    return (
      <>
        <div className="pageTbl" style={{ height: "calc(100vh - 180px)" }}>
          <table
            className="table table-bordered bg-white table-fixed table-hover"
            id="employeeLogsheetTable"
          >
            <thead className="table-secondary text-center">
              <tr>
                <th style={{ width: "12%" }}>Date</th>
                <th style={{ width: "13%" }}>Shift</th>
                <th style={{ width: "13%" }}>Attendance Status</th>
                <th style={{ width: "11%" }}>Check In</th>
                <th style={{ width: "20%" }}>Check In Detail</th>
                <th style={{ width: "11%" }}>Check Out</th>
                <th style={{ width: "20%" }}>Check Out Detail</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                data && data.length > 0 ? (
                  data.map((s, i) => (
                    <tr className="text-center" key={i}>
                      <td className="va_middle">{s?.date_display ? s?.date_display : "-"}</td>

                           <td className="text-center">{s?.shift.name || "-"}</td>
                           {s?.attendance && s?.attendance.length > 0 ? (
  <td>
    {s?.attendance.map((att, j) => (
      <div key={j} className="mt-1 link-primary cursor_pointer">
        {att.status ? (
          <Status color={att.status.color_code}>
            {att.status.name}
          </Status>
        ) : (
          <span>No Record</span>
        )}
      </div>
    ))}
  </td>
) : (
  <td>No Attendance Records</td>
)}
{s?.attendance && s?.attendance.length > 0 && (
  <td>
    {s?.attendance.map((att, j) => (
      <div key={j} className="mt-1">
        {att?.check_in_minutes ? (
          att?.check_in_minutes
        ) : (
          <span>-</span>
        )}
      </div>
    ))}
  </td>
)}
{s?.attendance && s?.attendance.length > 0 && (
  <td>
    {s?.attendance.map((att, j) => (
      <div key={j} className="mt-1">
        {att?.checkin_note ? (
          att?.checkin_note
        ) : (
          <span>-</span>
        )}
      </div>
    ))}
  </td>
)}
{s?.attendance && s?.attendance.length > 0 && (
  <td>
    {s.attendance.map((att, j) => (
      <div key={j} className="mt-1">
        {att.check_out_minutes ? (
          att.check_out_minutes
        ) : (
          <span>-</span>
        )}
      </div>
    ))}
  </td>
)}
{s?.attendance && s?.attendance.length > 0 && (
  <td>
    {s?.attendance.map((att, j) => (
      <div key={j} className="mt-1">
        {att?.checkout_note ? (
          att?.checkout_note
        ) : (
          <span>-</span>
        )}
      </div>
    ))}
  </td>
)}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      No Records
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td className="text-center" colSpan={7}>
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const selectDateRangeModalJsx = () => {
    const { selectDateRange, listingMeta } = monthlyAttendence;

    return (
      <div className="modal fade" id="selectDateRangeModal" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchItemModalLabel">
                Select Attendance Date Range
              </h5>
            </div>
            <div className="modal-body">
              <form
                className="bg-white p-1"
                id="searchDate"
                onSubmit={submitSearchDateRangeHandler}
              >
                <div className="row my-2">
                  <div className="row align-items-center mb-3 mt-2">
                    <div className="col-sm-5">
                      <label className="form-label">
                        Attendance Date Range
                      </label>
                    </div>
                    <div className="col-sm-7 add_calender_section">
                      <DatePicker
                        selectsRange={true}
                        monthsShown={2}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        autoComplete="off"
                        name="date"
                        startDate={selectDateRange.from}
                        endDate={selectDateRange.to}
                        onChange={(value) => tapDateChange(value)}
                        maxDate={moment().toDate()}
                        minDate={
                          new Date(listingMeta && listingMeta.first_date)
                        }
                      />
                      <TapIcon.imageIcon
                        icon={TapIcon.CalenderIcon}
                        className="add_calender-icon"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                form="searchDate"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="employeeCard" className="collapse pr-1 mt-1" aria-expanded="true"
      >
        <EmployeeDetailHorizontalCard
          employeeData={monthlyAttendence.employeeCardData}
          employee_loading={monthlyAttendence.employeeLoading}
        />
      </div>
      {attendanceSheetSubHeadingJsx()}
      <div className="container-fluid pl0 pr13">
        <div className="page_containt row">
          <div className="pl13 col-12 pr0">{employeeAttendenceListJsx()}</div>
        </div>
      </div>
      {selectDateRangeModalJsx()}
      <AttendenceUploadModal
        ref={bulkAttendenceModalRef}
        loadListingTblData={loadListingTblData} 
       />
    </>
  );
};

export default MonthlyAttendence;