import * as actionTypes from './actionTypes';
import HttpAPICall from '../../services/HttpAPICall';
import { appInitilizationDataLoad } from '.';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';

export const configurationAppDataInitilization   = () => {
    return {
        type                :   actionTypes.CONFIGURATION_APP_DATA_INIT
    };
};



export const configurationAppDataLoadSuccessfully   = (configuration_app_data) => {
    return {
        type                    :   actionTypes.CONFIGURATION_APP_DATA_SUCCESS,
        configuration_data                :   configuration_app_data,
    };
};


export const configurationAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.CONFIGURATION_APP_DATA_FAIL
    };
};

//***********************CONFIGURATION LISTING FUNCTIONS***************** *****/
export const configurationListingDataInitilization   = () => {
    return {
        type                :   actionTypes.CONFIGURATION_LISTING_DATA_INIT
    };
};



export const configurationListingDataLoadSuccessfully   = (configuration_listing_data,search_param,formSearchedElems) => {
    return {
        type                    :   actionTypes.CONFIGURATION_LISTING_DATA_SUCCESS,
        configuration_listing_data      :   configuration_listing_data,
        search_param            :   search_param,
        formSearchedElems       :   formSearchedElems
    };
};


export const configurationListingAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.CONFIGURATION_LISTING_DATA_FAIL
    };
};

export const configurationAppDataLoading         =   (user_info, group_info, acl_info)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        if (acl_info.group_modules.includes("configuration")) {
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/app_data', access_token, {})
                .then((response) => {
                    dispatch(configurationAppDataLoadSuccessfully(response.data));
                    dispatch(appInitilizationDataLoad(user_info, group_info, acl_info));
                }).catch(function (err) {
                    dispatch(configurationAppDataLoadFail());
                });
        }
    };
};




//***********************CONFIGURATION LISTING LOADING FUNCTION***************** *****/
export const configurationListingDataLoading         =   (page,searchParm,cl_attribute,formSearchedElems)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        let params                      =   {cl_attribute: cl_attribute, page: page, per_page: 50, ...searchParm};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/site_productivity_config/list', access_token, params, {} ,(response) => {
            let respData                =   response.data;
            dispatch(configurationListingDataLoadSuccessfully(respData,searchParm,formSearchedElems))
        })
            
        
    };
};
